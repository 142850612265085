import React from 'react';
import { Link } from "react-router-dom";

import logo from '../../img/logoheader.png'

export const TopMenuPC = () => {
  return (
    <div className="TopMenuPC">
      <div className="container">
        <a href="/" className="TopMenuPC__logo-block">
          <img src={logo} alt="logo" className="TopMenuPC__logo-block__logo" />
          {/*<img src={logoDesc} alt="Универсальная факторинговая компания" className="TopMenuPC__logo-block__desc" />*/}
        </a>
        <nav className="TopMenuPC__menu">
          <Link to="/fastTransfers" className="TopMenuPC__menu__item">Получить деньги</Link>
          {/* <a href="#link" className="TopMenuPC__menu__item" onClick={event => event.preventDefault()}>Вернуть деньги</a> */}
          <Link to="/operations" className="TopMenuPC__menu__item">Операции</Link>
          {/* <a href="#link" className="TopMenuPC__menu__item" onClick={event => event.preventDefault()}>Выписки</a> */}
          <Link to="/orders" className="TopMenuPC__menu__item">Заказы</Link>
          {/* <a href="#link" className="TopMenuPC__menu__item" onClick={event => event.preventDefault()}>Поддержка</a> */}
        </nav>
      </div>
    </div>
  );
}