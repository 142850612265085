import { PARTNERS } from "../../reducers/partnersRedusec";

export function getPartners() {
  return {
    type: PARTNERS.GET_PARTNERS_REQUEST
  }
}

export function addNewPartner(partnerData) {
  return {
    type: PARTNERS.ADD_NEW_PARTNER_REQUEST,
    payload: partnerData
  }
}

export function saveModifiedPartnerData(partnerData) {
  return {
    type: PARTNERS.SAVE_MODIFIED_DATA_REQUEST,
    payload: partnerData
  }
}

export function deletePartner(partnerId) {
  return {
    type: PARTNERS.DELETE_PARTNER_REQUEST,
    payload: partnerId
  }
}