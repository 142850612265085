import React from 'react';

import ResetPassForm from "../Forms/ResetPassForm";

import "./Login.css";

export const ResetPass = () => {
  return (
    <>
      <div className="Login_wrapper Recovery">
        <div className=" Login">
          <h3 className="Login__title">Восстановление пароля</h3>
          <ResetPassForm />
        </div>
      </div>
    </>
  )
}