import {
  takeEvery,
  put
} from 'redux-saga/effects';
import {
  PROFILE
} from "../../reducers/profileReducer";
import {
  ApiSetUserData,
  ApiSetNewPassword,
  ApiAddOperation, ApiRequestFinancing
} from '../../services/ApiCalls';
import {
  store
} from "../../index";
import {
  showMessage
} from "../../utility/functions";


function* setUserData(action) {
  try {
    yield ApiSetUserData(action.payload);
    yield put({
      type: PROFILE.ADD_USER_DATA_SUCCESS,
      payload: action.payload
    });
    showMessage('success', 'Данные успешно сохранены');
  } catch ({
    response
  }) {
    showMessage('error', 'Произошла ошибка');
    yield console.error(response.message);
  }
}

function* setNewPassword(action) {
  try {
    yield ApiSetNewPassword(action.payload);
    showMessage('success', 'Пароль успешно сохранен');
  } catch ({
    response
  }) {
    showMessage('error', 'Произошла ошибка');
    yield console.error(response.message);
  }
}

function* fastTransferMoney(action) {
  try {
    const profile = store.getState().profile;
    const finalBalance = profile.balance - action.payload.value;
    const finalLimit = profile.limit - action.payload.value;
    const data = {
      ...profile,
      balance: finalBalance,
    };
    const operation = {
      user_id: store.getState().profile.id,
      type_id: 1,
      shop_id: +action.payload.shopId,
      agent_id: null,
      delivery_service_id: null,
      status_id: 1,
      file_id: null,
      total: action.payload.value
    }
    ApiRequestFinancing({profile, finalBalance, finalLimit, sum: action.payload.value, shopId: action.payload.shopId});
    // ApiSetUserData(data);
    ApiAddOperation(operation);

    yield put({
      type: PROFILE.FAST_TRANSFER_MONEY_SUCCESS,
      payload: {
        balance: finalBalance,
        limit: finalLimit,
      }
    });
    showMessage('success', 'Перевод выполнен');
  } catch ({
    response
  }) {
    console.error(response.message);
  }
}

function* transferToPartner(action) {

  try {
    const profile = store.getState().profile;
    const userData = {
      ...profile,
      balance: profile.balance - action.payload.total
    };
    const operation = {
      user_id: store.getState().profile.id,
      type_id: 1,
      shop_id: null,
      agent_id: +action.payload.agentId,
      delivery_service_id: 1,
      status_id: 1,
      file_id: null,
      total: action.payload.total
    }
    ApiSetUserData(userData);
    ApiAddOperation(operation);
    yield put({
      type: PROFILE.TRANSFER_MONEY_TO_PARTNER_SUCCESS,
      payload: {
        balance: profile.balance - action.payload.total,
        limit: profile.limit - action.payload.total,
      },
    });
    yield showMessage('success', 'Перевод выполнен');
  } catch ({
    response
  }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

export default function* profileSaga() {
  yield takeEvery(PROFILE.ADD_USER_DATA_REQUEST, setUserData);
  yield takeEvery(PROFILE.SET_NEW_PASSWORD_REQUEST, setNewPassword);
  yield takeEvery(PROFILE.FAST_TRANSFER_MONEY_REQUEST, fastTransferMoney);
  yield takeEvery(PROFILE.TRANSFER_MONEY_TO_PARTNER_REQUEST, transferToPartner);
}