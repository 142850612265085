import React, { useEffect } from 'react';
import { getUsers } from "../../actions/users";
import { resetAuthorization } from "../../actions/app";
import { connect } from 'react-redux';

import TableRow from "./TableRow";

import './AdminPanel.css'

function AdminPanel(props) {
  const { users, getUsers, resetAuthorization } = props;
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div className="container">
      <div className="Table AdminPanel">
        <h3 className="Table__title block-title AdminPanel__title">Пользователи</h3>
        <button className="AdminPanel__exit-btn" onClick={() => {
          resetAuthorization();
          localStorage.removeItem('isAdmin');
        }} >Выйти</button>
        <table className="Table__table">
          <tbody>
            <tr>
              <th>
                Данные пользователя
              </th>
              <th>
                Баланс и лимит
              </th>
              <th>
                Опции
              </th>
            </tr>
            {users.map((elem, index) => <TableRow elem={elem} key={index} />)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  users: store.users,
});

export default connect(mapStateToProps, { getUsers, resetAuthorization })(AdminPanel);