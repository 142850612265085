import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { resetPass } from "../../actions/app";

const ResetPassForm = props => {
  const { resetPass } = props;
  const [passwordsError, setPasswordsError] = useState('');
  const schema = Yup.object().shape({
    firstPassword: Yup.string()
      .required('Введите пароль')
      .min(6, 'Минимальная длина 6 символа')
      .max(30, 'Максимальная длина 30 символов'),
    secondPassword: Yup.string()
      .required('Введите пароль')
      .min(6, 'Минимальная длина 6 символа')
      .max(30, 'Максимальная длина 30 символов'),
  });
  const formik = useFormik({
    initialValues: {
      firstPassword: '',
      secondPassword: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      if (values.firstPassword === values.secondPassword) {
        resetPass({
          token: window.location.search.split(/token=/)[1],
          password: values.firstPassword
        })
      } else {
        setPasswordsError('Пароли не совпадают')
      }
    }
  });
  const { values, errors, handleSubmit, handleChange, touched } = formik;
  return (
    <form className="Login__form" onSubmit={handleSubmit}>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${((touched.firstPassword && errors.firstPassword) || passwordsError) && 'Login__input-invalid'}`}
          type="password"
          name='firstPassword'
          placeholder="Введите пароль"
          value={values.firstPassword}
          onChange={handleChange}
          onClick={() => setPasswordsError('')}
        />
        {errors.firstPassword && touched.firstPassword && <div className='Login__invalid-message'>{errors.firstPassword}</div>}
        {passwordsError && <div className='Login__invalid-message'>{passwordsError}</div>}
      </div>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${((touched.secondPassword && errors.secondPassword) || passwordsError) && 'Login__input-invalid'}`}
          type="password"
          name='secondPassword'
          placeholder="Повторите пароль"
          value={values.secondPassword}
          onChange={handleChange}
          onClick={() => setPasswordsError('')}
        />
        {errors.secondPassword && touched.secondPassword && <div className='Login__invalid-message'>{errors.secondPassword}</div>}
        {passwordsError && <div className='Login__invalid-message'>{passwordsError}</div>}
      </div>
      <button className="Login__submitBtn" type="submit">Восстановить</button>
    </form>
  )
}

export default connect(null, { resetPass })(ResetPassForm)