import React, { useState } from 'react';
import { connect } from "react-redux";
import { addNewOrder, deleteOrder } from "../../actions/orders";
import { parseDate } from "../../utility/functions";

import { Preloader } from "../Modals/Preloader";
import { TableRow } from "./TableRow";
import AddNewOrder from "./AddNewOrder";


const Table = props => {
  const { orders, ordersLoading, filter, deleteOrder } = props;
  const [visibleAddOrderComponent, toggleVisibleAddOrderComponent] = useState(false);

  function parseFilteredOrders() {
    const newArr = orders.filter(elem => {
      for (let key in filter) {
        if (key === 'dateFrom') {
          if (parseDate(elem.created_at) < parseDate(filter.dateFrom)) {
            return false;
          };
        } else if (key === 'dateTo') {
          if (parseDate(elem.created_at) > parseDate(filter.dateTo)) {
            return false;
          };
        } else if (filter[key] !== elem[key]) {
          return false;
        }
      }
      return true;
    });
    return newArr.map((elem, index) => <TableRow elem={elem} key={index} />);
  }
  return (
    <>
      <div className="Table Table-orders">
        <h3 className="Table__title block-title">Таблица заказов</h3>
        {ordersLoading && <Preloader />}
        <table className="Table__table">
          <tbody>
            <tr>
              <th>Дата</th>
              <th>Сумма</th>
              <th>Тип оплаты</th>
              <th>Магазин</th>
              <th>Служба доставки</th>
            </tr>
            {
              Object.keys(filter).length ?
                parseFilteredOrders() :
                orders.map((elem, index) => <TableRow elem={elem} key={index} deleteOrder={deleteOrder} />)
            }
          </tbody>
        </table>
        <button className="Shops__addShop Table-orders__add-btn" onClick={() => toggleVisibleAddOrderComponent(!visibleAddOrderComponent)} >
          добавить заказ
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#0B3043" />
          </svg>
        </button>
      </div>
      {visibleAddOrderComponent && <AddNewOrder toggleVisibleAddOrderComponent={toggleVisibleAddOrderComponent} />}
    </>
  );
}

const mapStateToProps = store => ({
  orders: store.orders,
  ordersLoading: store.app.ordersLoading,
  filter: store.app.filterForOrders,
});

export default connect(mapStateToProps, { addNewOrder, deleteOrder })(Table)