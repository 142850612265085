import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAuthorization } from "../../actions/app";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const LoginPageForm = props => {
  const [rememberMe, toggleRememberMe] = useState(true);
  const { getAuthorization } = props;
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Введите почту')
      .email('Введите почту')
      .min(3, 'Минимальная длина 3 символа'),
    password: Yup.string()
      .min(6, 'Минимальная длина 6 символов')
      .required('Введите пароль')
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: schema,
    onSubmit: values => {
      getAuthorization(values, rememberMe);
    }
  });
  const { values, errors, handleSubmit, handleChange, touched } = formik;
  return (
    <form className="Login__form" onSubmit={handleSubmit}>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.email && errors.email) && 'Login__input-invalid'}`}
          type="text"
          name='email'
          placeholder="Email"
          value={values.email}
          onChange={handleChange}
        />
        {errors.email && touched.email && <div className='Login__invalid-message'>{errors.email}</div>}
      </div>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.password && errors.password) && 'Login__input-invalid'}`}
          type="password"
          name='password'
          placeholder="Пароль"
          value={values.password}
          onChange={handleChange}
        />
        {errors.password && touched.password && <div className='Login__invalid-message'>{errors.password}</div>}
      </div>
      <div className="Login__row">
        <label className="Login__label" >
          <input
            className="Login__checkbox"
            type="checkbox"
            name="rememberMe"
            id="rememberMe"
            onClick={() => toggleRememberMe(!rememberMe)}
            defaultChecked
          />
          <span>Запомнить меня</span>
        </label>
        <Link to="/recoveryPass" className="Login__recovery-pass">Забыли пароль?</Link>
      </div>
      <button className="Login__submitBtn" type="submit">Войти</button>
    </form>
  )
}

export default connect(() => ({}), { getAuthorization })(LoginPageForm)