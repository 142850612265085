import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { setUserData } from "../../actions/profile";
import { connect } from "react-redux";


const InfoForm = props => {
  const { name, surname, email, telephone } = props.profile;
  const { setUserData } = props;
  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Минимальная длина 3 символа'),
    surname: Yup.string()
      .min(3, 'Минимальная длина 3 символа'),
    email: Yup.string()
      .email('Введите email'),
    telephone: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .positive()
      .min(99999, 'Телефон не может содержать меньше 6 символов')
      .max(100000000000, 'Телефон не может содержать больше 11 символов')
  });
  const formik = useFormik({
    initialValues: {
      name: name,
      surname: surname,
      email: email,
      telephone: telephone,
    },
    validationSchema: schema,
    onSubmit: values => {
      const userData = { ...props.profile };
      let dataChanged = false;
      // проверка, были ли изменены данные пользователя, если да, 
      // то отправляем их
      for (const key in values) {
        if (values[key] !== formik.initialValues[key]) {
          dataChanged = true;
          userData[key] = values[key];
        }
      };
      if (dataChanged) {
        setUserData(userData);
      };
    }
  });
  const { values, errors, handleSubmit, handleChange, touched } = formik;
  return (
    <form id="profileData" onSubmit={handleSubmit}>
      <div className="Info__info__wrapper">
        <input
          type="text"
          className={`Info__info__input ${touched.name && errors.name && 'Info__info__input-invalid'}`}
          name='name'
          value={values.name}
          placeholder='Имя'
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.name && touched.name && <div className='invalid-message invalid-message-info'>{errors.name}</div>}
      </div>
      <div className="Info__info__wrapper">
        <input
          type="text"
          className={`Info__info__input ${touched.surname && errors.surname && 'Info__info__input-invalid'}`}
          name='surname'
          value={values.surname}
          placeholder='Фамилия'
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.surname && touched.surname && <div className='invalid-message invalid-message-info'>{errors.surname}</div>}
      </div>
      <div className="Info__info__wrapper">
        <input
          type="text"
          className={`Info__info__input ${touched.email && errors.email && 'Info__info__input-invalid'}`}
          name='email'
          value={values.email}
          placeholder='Email'
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.email && touched.email && <div className='invalid-message invalid-message-info'>{errors.email}</div>}
      </div>
      <div className="Info__info__wrapper">
        <input
          type="text"
          className={`Info__info__input ${touched.telephone && errors.telephone && 'Info__info__input-invalid'}`}
          name='telephone'
          value={values.telephone}
          placeholder='Телефон'
          onChange={handleChange}
          autoComplete="off"
        />
        {errors.telephone && touched.telephone && <div className='invalid-message invalid-message-info'>{errors.telephone}</div>}
      </div>
    </form>
  )
}

const mapStateToProps = store => ({
  profile: store.profile,
});

export default connect(mapStateToProps, { setUserData })(InfoForm)