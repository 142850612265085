import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { formatValue } from "../../utility/functions";
import { toggleVisibleDropDownMenu } from "../../actions/app";
import { showModal } from "../../utility/functions";
import { RequestCalRef } from "../Modals/RequestCallModal";
import { ConfirmExitRef } from "../Modals/ConfirmExit";

export function DropDownMenu(props) {
  const { balance, name, surname, toggleVisibleDropDownMenu, email } = props;
  return (
    <div className="DropDownMenu">
      <nav className="DropDownMenu__nav">
        <Link to="/fastTransfers" className="DropDownMenu__nav__link" onClick={toggleVisibleDropDownMenu}>Получить деньги</Link>
        {/* <a href="#link" className="DropDownMenu__nav__link" onClick={event=> {
          event.preventDefault();
          toggleVisibleDropDownMenu();}}>Вернуть деньги</a> */}
        <Link to="/operations" className="DropDownMenu__nav__link" onClick={toggleVisibleDropDownMenu}>Операции</Link>
        {/* <a href="#link" className="DropDownMenu__nav__link" onClick={event=> {
          event.preventDefault();
          toggleVisibleDropDownMenu();}}>Выписки</a> */}
        <Link to="/orders" className="DropDownMenu__nav__link" onClick={toggleVisibleDropDownMenu}>Заказы</Link>
        {/* <a href="#link" className="DropDownMenu__nav__link" onClick={event=> {
          event.preventDefault();
          toggleVisibleDropDownMenu();}}>Поддержка</a> */}
      </nav>
      <div className="DropDownMenu__userInfo">
        <Link to="/" className="DropDownMenu__userInfo__name" onClick={toggleVisibleDropDownMenu} title="Перейти в профиль">
          {(name && surname) ? `${name} ${surname}` : `${email}`}
          <svg className="DropDownMenu__userInfo__icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2891 13.417C17.1569 13.417 21.9141 15.6369 21.9141 18.3753V21.917H0.664062V18.3753C0.664062 15.6369 5.42123 13.417 11.2891 13.417ZM20.4974 18.3753C20.4974 16.4203 16.3749 14.8337 11.2891 14.8337C6.20323 14.8337 2.08073 16.4203 2.08073 18.3753V20.5003H20.4974V18.3753ZM11.2891 0.666992C12.6041 0.666992 13.8653 1.18939 14.7951 2.11925C15.725 3.04912 16.2474 4.31029 16.2474 5.62533C16.2474 6.94036 15.725 8.20153 14.7951 9.1314C13.8653 10.0613 12.6041 10.5837 11.2891 10.5837C9.97403 10.5837 8.71286 10.0613 7.78299 9.1314C6.85312 8.20153 6.33073 6.94036 6.33073 5.62533C6.33073 4.31029 6.85312 3.04912 7.78299 2.11925C8.71286 1.18939 9.97403 0.666992 11.2891 0.666992ZM11.2891 2.08366C10.3498 2.08366 9.44892 2.4568 8.78473 3.12099C8.12053 3.78518 7.7474 4.68602 7.7474 5.62533C7.7474 6.56463 8.12053 7.46547 8.78473 8.12966C9.44892 8.79385 10.3498 9.16699 11.2891 9.16699C12.2284 9.16699 13.1292 8.79385 13.7934 8.12966C14.4576 7.46547 14.8307 6.56463 14.8307 5.62533C14.8307 4.68602 14.4576 3.78518 13.7934 3.12099C13.1292 2.4568 12.2284 2.08366 11.2891 2.08366Z" fill="#0B3043" />
          </svg>
        </Link>
        <p className="DropDownMenu__userInfo__little-text">Баланс </p>
        <p className="DropDownMenu__userInfo__balance">{formatValue(balance)} рублей</p>
      </div>
      <div className="DropDownMenu__buttons">
        <button className="DropDownMenu__buttons__exit" onClick={()=> showModal(ConfirmExitRef.current)}>выйти</button>
        <div className="DropDownMenu__buttons__call" onClick={()=>{
          toggleVisibleDropDownMenu();
          showModal(RequestCalRef.current);
          }}>
          +7 (000) 000-00-00
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00995827 13.8067C0.0517082 13.5487 0.213457 13.3282 0.455708 13.1973L4.03946 11.0858L4.06896 11.0693C4.21923 10.994 4.38517 10.9555 4.55321 10.9568C4.85346 10.9568 5.14021 11.0705 5.33921 11.27L6.39696 12.3282C6.44221 12.3712 6.58971 12.4335 6.63446 12.4357C6.64671 12.4347 7.86521 12.347 10.1085 10.1035C12.3477 7.86475 12.4427 6.6425 12.4435 6.6425C12.4422 6.58 12.3807 6.433 12.337 6.3875L11.435 5.48575C11.1172 5.16725 11.022 4.63925 11.2105 4.23025L13.2025 0.482C13.3472 0.184 13.6285 0 13.9405 0C14.1612 0 14.3742 0.0915 14.5407 0.25775L16.9995 2.711C17.2352 2.9455 17.4382 3.3555 17.4825 3.6855C17.504 3.84325 17.9402 7.60875 12.7777 12.772C8.39496 17.1542 4.97746 17.4947 4.03371 17.4947C3.91878 17.4962 3.80387 17.4903 3.68971 17.477C3.36071 17.433 2.95121 17.2305 2.71696 16.9957L0.260208 14.5392C0.0597076 14.3377 -0.0317917 14.0715 0.00995827 13.8067Z" fill="white" />
          </svg>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  balance: store.profile.balance,
  name: store.profile.name,
  email: store.profile.email,
  surname: store.profile.surname,
});

export default connect(mapStateToProps, { toggleVisibleDropDownMenu })(DropDownMenu);
