import React from 'react';
import { connect } from "react-redux";
import { showModal } from "../../utility/functions";

import { ShopsListItem } from "./ShopsListItem";
import {AddShopModal, AddShopModalRef} from "../Modals/AddShopModal";
import {EditShopModal} from "../Modals/EditShopModal";
import { Preloader } from "../Modals/Preloader";

const Shops = props => {
  const { shops, editableShop, shopsLoading } = props;
  return (
    <div className="Shops">
      {shopsLoading && <Preloader />}
      <AddShopModal />
      {editableShop && <EditShopModal />}
      <h3 className="Shops__title Info__info__title">Интернет-магазины</h3>
      <div className="Shops__shops-block">
        {shops.map((elem, index) => <ShopsListItem elem={elem} indexInArr={index} key={elem.id} />)}
      </div>
      <button className="Shops__addShop" onClick={() => showModal(AddShopModalRef.current)}>
        добавить магазин
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#0B3043" />
        </svg>
      </button>
    </div>
  );
}

const mapStateToProps = store => ({
  shops: store.shops,
  editableShop: store.app.editableShop,
  shopsLoading: store.app.shopsLoading,
})


export default connect(mapStateToProps)(Shops)