import { takeEvery, put } from "redux-saga/effects";
import { ORDERS } from "../../reducers/ordersReducer";
import { APP } from "../../reducers/appReducer";
import { ApiGetOrders, ApiAddNewOrder, ApiDeleteOrder } from "../../services/ApiCalls";

function* getOrders() {
  try {
    const { data } = yield ApiGetOrders();
    yield put({
      type: ORDERS.GET_ORDERS_SUCCESS,
      payload: data
    });
    yield put({
      type: APP.SET_ORDERS_LOADING_FALSE
    });

  } catch ({ response }) {
    console.error(response.data.errors);
  }
}

function* addNewOrder(action) {
  try {
    yield ApiAddNewOrder(action.payload);
    yield put({
      type: APP.SET_ORDERS_LOADING_TRUE
    });
    yield put({
      type: APP.GET_PROFILE_REQUEST
    });
    const { data } = yield ApiGetOrders();
    yield put({
      type: ORDERS.GET_ORDERS_SUCCESS,
      payload: data
    });
    yield put({
      type: APP.SET_ORDERS_LOADING_FALSE
    });
  } catch ({ response }) {
    console.error(response.data.errors);
  }
}

function* deleteOrder(action) {
  yield ApiDeleteOrder(action.payload);
  yield put({
    type: APP.SET_ORDERS_LOADING_TRUE
  });
  yield put({
    type: APP.GET_PROFILE_REQUEST
  });
  const { data } = yield ApiGetOrders();
  yield put({
    type: ORDERS.GET_ORDERS_SUCCESS,
    payload: data
  });
  yield put({
    type: APP.SET_ORDERS_LOADING_FALSE
  });
}

export default function* ordersSaga() {
  yield takeEvery(ORDERS.GET_ORDERS_REQUEST, getOrders);
  yield takeEvery(ORDERS.ADD_NEW_ORDER_REQUEST, addNewOrder);
  yield takeEvery(ORDERS.DELETE_ORDER_REQUEST, deleteOrder);
}