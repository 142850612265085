import React from 'react';
import { formatValue } from "../../utility/functions";

export const TableRow = props => {
  const { elem } = props;
  return (
    <tr key={elem.id}>
      <td>{elem.created_at}</td>
      <td>{elem.status}</td>
      <td>{elem.typeOfOperation}</td>
      <td>{elem.shopName}</td>
      <td>{elem.agentName}</td>
      <td>{elem.deliveryService}</td>
      <td>{formatValue(elem.total) + ' руб.'}</td>
    </tr>
  )
}