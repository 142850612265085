export const USERS = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  SET_LIMIT_REQUEST: 'SET_LIMIT_REQUEST',
  BLOCK_USER_REQUEST: 'BLOCK_USER_REQUEST',
  BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
  UNLOCK_USER_REQUEST: 'UNLOCK_USER_REQUEST',
  UNLOCK_USER_SUCCESS: 'UNLOCK_USER_SUCCESS',
}

const initialState = []

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case USERS.GET_USERS_SUCCESS:
      return [...action.payload];   
    case USERS.BLOCK_USER_SUCCESS: {
      let arr = [];
      state.forEach(elem => {
        if(elem.id === action.payload) {
          arr.push({...elem, status: 0});
          return null;
        }
        arr.push(elem);
      });
      return [...arr];
    }
    case USERS.UNLOCK_USER_SUCCESS: {
      let arr = [];
      state.forEach(elem => {
        if(elem.id === action.payload) {
          arr.push({...elem, status: 1});
          return null;
        }
        arr.push(elem);
      });
      return [...arr];
    }
    default:
      return state;
  }
}