import React, {createRef} from 'react';

export const successMessageModalRef = createRef();

export const SuccessMessageModal = () => {
  return (
    <div className="Status-message Status-message__success" ref={successMessageModalRef}>
      <div className="Status-message__icon Status-message__icon-success">
        <svg width="45" height="34" viewBox="0 0 45 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M43.1019 1.4036C41.2638 0.0445571 38.6911 0.31971 37.1819 2.03674L21.3229 20.0805C19.1498 22.5529 15.3906 22.8129 12.8978 20.6633L6.73968 15.3528C5.23743 14.0573 3.01291 14.0577 1.51112 15.3537C-0.338822 16.9502 -0.338821 19.8173 1.51113 21.4137L13.9681 32.1638C16.4386 34.2957 20.16 34.0624 22.3448 31.6385L43.7542 7.88665C45.4936 5.95692 45.1909 2.94811 43.1019 1.4036Z" fill="#279151" />
        </svg>
      </div>
      <p className="Status-message__message"></p>
    </div>
  )
}