import React from 'react';
import RegistrationPageForm from "../Forms/RegistrationPageForm";
import { Link } from "react-router-dom";

import { ErrorMessageModal } from "../Modals/ErrorMessageModal";
import { SuccessMessageModal } from "../Modals/SuccessMessageModal";

import "./Login.css";

export const RegistrationPage = () => {
  return (
    <>
      <ErrorMessageModal />
      <SuccessMessageModal />
      <div className="Login_wrapper">
        <div className=" Login">
          <h3 className="Login__title">Регистрация</h3>
          <RegistrationPageForm />
          <Link to="/login" className="Login__registration-link">Войти</Link>
        </div>
      </div>
    </>
  )
}