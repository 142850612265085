import axios from 'axios';

function Service(httpMethod, path, payload, headers) {
  const cacheToken = sessionStorage.token || localStorage.token;
  const headerAuth = cacheToken && {
    Authorization: `Bearer ${cacheToken}`
  };
  const url = 'http://ufkfaktor.ru/api';

  const service = axios.create({
    headers: {
      ...headers,
      ...headerAuth
    }
  });

  const handleSuccess = response => response;

  const handleError = error => {
    return Promise.reject(error);
  };

  service.interceptors.response.use(handleSuccess, handleError);

  switch (httpMethod) {
    case 'get':
      return service.get(`${url}${path}`);
    case 'post':
      return service.request({
        method: 'POST',
        url: `${url}${path}`,
        data: payload
      });
    case 'patch':
      return service.request({
        method: 'PATCH',
        url: `${url}${path}`,
        data: payload
      });
    case 'put':
      return service.request({
        method: 'PUT',
        url: `${url}${path}`,
        data: payload
      });
    case 'delete':
      return service.request({
        method: 'DELETE',
        url: `${url}${path}`,
        data: payload
      });
    case 'deleteItems':
      return service.request({
        method: 'DELETE',
        url: `${url}${path}`,
        data: payload
      });
    default:
      break;
  }
}

export default Service;