import React from 'react';
import { Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PublicRoute, PrivateRoute } from "./components/Route";

import { LoginPage } from "./components/Authorization/LoginPage";
import { RegistrationPage } from "./components/Authorization/RegistrationPage";
import { RecoveryPass } from "./components/Authorization/RecoveryPass";
import { ResetPass } from "./components/Authorization/ResetPass";
import Layout from "./components/Layout";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import { SuccessMessageModal } from "./components/Modals/SuccessMessageModal";
import { ErrorMessageModal } from "./components/Modals/ErrorMessageModal";

import './components/App.css';
import './components/myNormalize.css';
import './components/fonts.css';



export const appBrowserHistory = createBrowserHistory();

export function App() {
  return (
    <>
      <SuccessMessageModal />
      <ErrorMessageModal />
      <Switch>
        <PublicRoute exact path="/login" component={LoginPage} />
        <PublicRoute exact path="/registration" component={RegistrationPage} />
        <PublicRoute exact path="/recoveryPass" component={RecoveryPass} />
        <PublicRoute exact path="/resetPass" component={ResetPass} />
        <Route path="/adminPanel" component={AdminPanel} />
        <PrivateRoute path="/" component={Layout} />
      </Switch>
    </>
  );
}