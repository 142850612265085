import React from 'react';
import { Link } from "react-router-dom";
import { showModal } from "../../utility/functions";
import { RequestCalRef } from "../Modals/RequestCallModal";
import { ConfirmExitRef } from "../Modals/ConfirmExit";

export const TopLine = props => {
  const { name, surname, email } = props.userData;

  return (
    <div className="TopLine">
      <div className="container">
        <Link to="/" className="TopLine__profile">
          <svg className="TopLine__profile__icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2891 13.417C17.1569 13.417 21.9141 15.6369 21.9141 18.3753V21.917H0.664062V18.3753C0.664062 15.6369 5.42123 13.417 11.2891 13.417ZM20.4974 18.3753C20.4974 16.4203 16.3749 14.8337 11.2891 14.8337C6.20323 14.8337 2.08073 16.4203 2.08073 18.3753V20.5003H20.4974V18.3753ZM11.2891 0.666992C12.6041 0.666992 13.8653 1.18939 14.7951 2.11925C15.725 3.04912 16.2474 4.31029 16.2474 5.62533C16.2474 6.94036 15.725 8.20153 14.7951 9.1314C13.8653 10.0613 12.6041 10.5837 11.2891 10.5837C9.97403 10.5837 8.71286 10.0613 7.78299 9.1314C6.85312 8.20153 6.33073 6.94036 6.33073 5.62533C6.33073 4.31029 6.85312 3.04912 7.78299 2.11925C8.71286 1.18939 9.97403 0.666992 11.2891 0.666992ZM11.2891 2.08366C10.3498 2.08366 9.44892 2.4568 8.78473 3.12099C8.12053 3.78518 7.7474 4.68602 7.7474 5.62533C7.7474 6.56463 8.12053 7.46547 8.78473 8.12966C9.44892 8.79385 10.3498 9.16699 11.2891 9.16699C12.2284 9.16699 13.1292 8.79385 13.7934 8.12966C14.4576 7.46547 14.8307 6.56463 14.8307 5.62533C14.8307 4.68602 14.4576 3.78518 13.7934 3.12099C13.1292 2.4568 12.2284 2.08366 11.2891 2.08366Z" fill="#0B3043" />
          </svg>
          {(name && surname) ? `${name} ${surname}` : `${email}`}
        </Link>
        <button className="TopLine__exit-btn" onClick={()=> showModal(ConfirmExitRef.current)}>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.625 5.84375V4.51562C10.625 4.16338 10.4851 3.82557 10.236 3.5765C9.98693 3.32743 9.64912 3.1875 9.29688 3.1875H2.92188C2.56963 3.1875 2.23182 3.32743 1.98275 3.5765C1.73368 3.82557 1.59375 4.16338 1.59375 4.51562V12.4844C1.59375 12.8366 1.73368 13.1744 1.98275 13.4235C2.23182 13.6726 2.56963 13.8125 2.92188 13.8125H9.29688C9.64912 13.8125 9.98693 13.6726 10.236 13.4235C10.4851 13.1744 10.625 12.8366 10.625 12.4844V11.1562" stroke="#0B3043" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.75 5.84375L15.4062 8.5L12.75 11.1562" stroke="#0B3043" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.3418 8.5H15.4062" stroke="#0B3043" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <a href="tel:+78452398188" className="TopLine__phone">+7 (8452) 398-188</a>
        <div className="TopLine__requestCall" onClick={()=> showModal(RequestCalRef.current)}>Заказать звонок</div>
      </div>
    </div>
  )
}
