import React, { useEffect } from 'react';
import { getOperations } from "../../actions/operations";
import { connect } from "react-redux";

import Filter from "./FilterOperations";
import Table from "./TableWithOperationsList";

import "./Operations.css";

const Operations = props => {
  useEffect(()=>{
    if(props.statusOfAuthorization) {
      props.getOperations();
    }
  });
  return (
    <div className='Operations'>
      <div className='container'>
        <h1 className="Pagetitle">Операции</h1>
        <Filter />
        <Table />
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  statusOfAuthorization: store.app.statusOfAuthorization,
});

export default connect(mapStateToProps, { getOperations })(Operations)