import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { setOperationsFilter, deleteFieldInOperationsFilter } from "../../actions/app/index";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

import { ServicesDelivery } from "./ServicesDeliveryOnFilter";
import { ShopNameOptions } from "./ShopsNamesOnFilter";
import { StatusOptions } from "./StatusOnFilter";

export const Filter = props => {

  const { operations, setOperationsFilter, deleteFieldInOperationsFilter } = props;
  let shopsNames = [];
  let servicesDelivery = [];
  let statuses = [];
  const [visibleShopsOptions, toggleVisibleShopsOptions] = useState(false);
  const [visibleServicesOptions, toggleVisibleServicesOptions] = useState(false);
  const [visibleStatusOptions, toggleVisibleStatusOptions] = useState(false);
  const [selectedShop, selectShop] = useState('');
  const [selectedService, selectService] = useState('');
  const [selectedStatus, selectStatus] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [convertedDateFrom, setConvertedDateFrom] = useState('');
  const [convertedDateTo, setConvertedDateTo] = useState('');
  registerLocale('ru', ru);


  // при выборе какого-либо параметра в фильтре, добавляем эти параметры в стор
  useEffect(() => {
    const data = {};
    if (selectedShop || selectedService || selectedStatus || convertedDateFrom || convertedDateTo) {
      if (selectedShop) {
        data.shopName = selectedShop;
      };
      if (selectedService) {
        data.deliveryService = selectedService;
      };
      if (selectedStatus) {
        data.status = selectedStatus;
      };
      if (convertedDateFrom) {
        data.dateFrom = convertedDateFrom;
      };
      if (convertedDateTo) {
        data.dateTo = convertedDateTo;
      };
      setOperationsFilter(data);
    };
  }, [selectedShop, selectedService, selectedStatus, convertedDateFrom, convertedDateTo]);

  operations.forEach(elem => {
    if (!shopsNames.includes(elem.shopName) && elem.shopName !== null) {
      shopsNames.push(elem.shopName)
    };
    if (!servicesDelivery.includes(elem.deliveryService) && elem.deliveryService !== null) {
      servicesDelivery.push(elem.deliveryService)
    };
    if (!statuses.includes(elem.status) && elem.status !== null) {
      statuses.push(elem.status)
    };
  });

  function handleClickOnSelectField(typeOfSelectField, event) {
    if (event.target.classList[0] === 'Filter__form__select' || event.target.className === 'Filter__form__select_option' || event.target.className === 'Filter__form__select_arrow') {
      switch (typeOfSelectField) {
        case 'shopsName':
          toggleVisibleShopsOptions(!visibleShopsOptions);
          break;
        case 'services':
          toggleVisibleServicesOptions(!visibleServicesOptions);
          break;
        case 'status':
          toggleVisibleStatusOptions(!visibleStatusOptions);
          break;
      }
    }
  }

  // переводим дату из формата new Date() в формат "01.01.2020"
  function convertDate(date, dataType) {
    const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const dateString = `${day}.${month}.${date.getFullYear()}`;
    if (dataType === 'from') {
      setConvertedDateFrom(dateString);
    } else {
      setConvertedDateTo(dateString);
    };
  }

  return (
    <div className="Filter Filter-operations">
      <h3 className="Filter__title block-title">Фильтр</h3>
      <form action="GET" className="Filter__form Filter-operations__form">
        <div className="Filter__form__wrapper Filter-operations__wrapper">
          <p className="Filter__form__inputTitle">Дата</p>
          <div className="Filter__form__inputDate_wrapper">
            <DatePicker
              selected={dateFrom}
              onChange={date => {
                convertDate(date, 'from');
                setDateFrom(date);
              }}
              locale='ru'
              className="Filter__form__inputDate"
              dateFormat="d MMM yyyy"
            />
            {convertedDateFrom && <svg className="Filter__form__inputDate_clear-btn" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              setConvertedDateFrom('');
              setDateFrom('');
              deleteFieldInOperationsFilter('dateFrom');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
          </div>
          <div className="Filter__form__inputDate_wrapper">
            <DatePicker
              selected={dateTo}
              onChange={date => {
                convertDate(date, 'to');
                setDateTo(date);
              }}
              locale='ru'
              className="Filter__form__inputDate"
              dateFormat="d MMM yyyy"
            />
            {convertedDateTo && <svg className="Filter__form__inputDate_clear-btn" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              setConvertedDateTo('');
              setDateTo('');
              deleteFieldInOperationsFilter('dateTo');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
          </div>
        </div>
        <div className="Filter__form__wrapper Filter-operations__wrapper">
          <p className="Filter__form__inputTitle">Магазин</p>
          <div className="Filter__form__select Filter-operations__select shopNames" onClick={event => handleClickOnSelectField('shopsName', event)}>{selectedShop}
            {selectedShop && <svg className="Filter__form__select_cross" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              selectShop('');
              deleteFieldInOperationsFilter('shopName');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
            <svg className="Filter__form__select_arrow shopNames" width="10" height="6" viewBox="0 0 10 6" fill="none" onClick={() => toggleVisibleShopsOptions(!visibleShopsOptions)} >
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>
          </div>
          {visibleShopsOptions && <ShopNameOptions shopsNames={shopsNames} selectShop={selectShop} toggleVisibleShopsOptions={toggleVisibleShopsOptions} />}
        </div>
        <div className="Filter__form__wrapper Filter-operations__wrapper">
          <p className="Filter__form__inputTitle">Служба доставки</p>
          <div className="Filter__form__select Filter-operations__select servicesDelivery" onClick={event => handleClickOnSelectField('services', event)}>
            {selectedService}
            {selectedService && <svg className="Filter__form__select_cross" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              selectService('');
              deleteFieldInOperationsFilter('deliveryService');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
            <svg className="Filter__form__select_arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" onClick={() => toggleVisibleServicesOptions(!visibleServicesOptions)}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>
          </div>
          {visibleServicesOptions && <ServicesDelivery selectService={selectService} servicesDelivery={servicesDelivery} toggleVisibleServicesOptions={toggleVisibleServicesOptions} />}
        </div>
        <div className="Filter__form__wrapper Filter-operations__wrapper">
          <p className="Filter__form__inputTitle">Статус</p>
          <div className="Filter__form__select Filter-operations__select servicesDelivery" onClick={event => handleClickOnSelectField('status', event)}>
            {selectedStatus}
            {selectedStatus && <svg className="Filter__form__select_cross" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              selectStatus('');
              deleteFieldInOperationsFilter('status');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
            <svg className="Filter__form__select_arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" onClick={() => toggleVisibleStatusOptions(!visibleStatusOptions)}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>
          </div>
          {visibleStatusOptions && <StatusOptions selectStatus={selectStatus} statuses={statuses} toggleVisibleStatusOptions={toggleVisibleStatusOptions} />}
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = store => ({
  operations: store.operations,
});

export default connect(mapStateToProps, { setOperationsFilter, deleteFieldInOperationsFilter })(Filter);