import React, { Fragment } from 'react';
import { connect } from "react-redux";

import {TopLine} from "./TopLine";
import { TopMenuPC } from "./TopMenuPC";
import TopMenuMob from "./TopMenuMob";
import DropDownMenu from './DropDownMenu'

import "./Header.css";

const Header = props => {
  const {name, email, surname} = props;
  return (
    <Fragment>
      {document.documentElement.clientWidth > 768 ? <TopLine userData={{name, email, surname}} /> : null}
      {document.documentElement.clientWidth > 768 ? <TopMenuPC /> : <TopMenuMob />}
      {props.visibleDropDownMenu && <DropDownMenu />}
    </Fragment>
  )
}

const mapStateToProps = store => ({
  name: store.profile.name,
  surname: store.profile.surname,
  email: store.profile.email,
  visibleDropDownMenu: store.app.visibleDropDownMenu,
})

export default connect(mapStateToProps)(Header);