import React from 'react';
import { connect } from "react-redux";
import { TableRow } from "./TableRow";
import { Preloader } from "../Modals/Preloader";
import { parseDate } from "../../utility/functions";

const Table = props => {
  const { operations, operationsLoading, filter } = props;

  function parseFilteredOrders() {
    const newArr = operations.filter(elem => {
      for (let key in filter) {
        if (key === 'dateFrom') {
          if (parseDate(elem.created_at) < parseDate(filter.dateFrom)) {
            return false;
          };
        } else if (key === 'dateTo') {
          if (parseDate(elem.created_at) > parseDate(filter.dateTo)) {
            return false;
          };
        } else if (filter[key] !== elem[key]) {
          return false;
        }
      }
      return true;
    });
    return newArr.map((elem, index) => <TableRow elem={elem} key={index} />);
  }

  return (
    <div className="Table Table-operations">
      <h3 className="Table__title block-title">Таблица операций</h3>
      {operationsLoading && <Preloader />}
      <table className="Table__table">
        <tbody>
          <tr>
            <th>
              Дата
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
            <th>
              Статус
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
            <th>
              Тип операции
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
            <th>
              Магазин
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
            <th>
              Контрагент
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
            <th>
              Служба доставки
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
            <th>
              Сумма
                {/* <svg className="Table__table__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              </svg> */}
            </th>
          </tr>
          {
            Object.keys(filter).length ?
              parseFilteredOrders() :
              operations.map((elem, index) => <TableRow elem={elem} key={index} />)
          }
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = store => ({
  operations: store.operations,
  operationsLoading: store.app.operationsLoading,
  filter: store.app.filterForOperations,
});

export default connect(mapStateToProps)(Table)