import Service from './Service';

export async function ApiGetProfile() {
    const { data } = await Service('get', `/profile`);
    return data;
}

export async function ApiAuthorize(authorizeData) {
    const { data } = await Service('post', `/login`, authorizeData, { 'Content-Type': 'application/json' });
    return data;
}

export async function ApiGetShops() {
    const { data } = await Service('get', `/shops`);
    return data;
}

export async function ApiGetOrders() {
    const { data } = await Service('get', '/orders');
    return data;
}

export async function ApiSetUserData(userData) {
    const data = await Service('put', '/userUpdate', userData);
    return data;
}

export async function ApiSetNewPassword(newPassword) {
    const data = await Service('put', '/changePassword', newPassword);
    return data;
}

export async function ApiSaveEditShopData(shopData) {
    const data = await Service('put', '/shops', shopData)
    return data;
}

export async function ApiAddNewShop(shopData) {
    const data = await Service('post', '/shops', shopData, { 'Content-Type': 'application/json' });
    return data;
}

export async function ApiDeleteShop(shopId) {
    const data = await Service('delete', '/shops', shopId);
    return data;
}

export async function ApiGetPartners() {
    const { data } = await Service('get', '/agents');
    return data;
}

export async function ApiAddNewPartner(partnerData) {
    const data = await Service('post', '/agents', partnerData, { 'Content-Type': 'application/json' });
    return data;
}

export async function ApiSaveEditPartnerData(partnerData) {
    const data = await Service('put', '/agents', partnerData)
    return data;
}

export async function ApiDeletePartner(partnerId) {
    const data = await Service('delete', '/agents', partnerId);
    return data;
}

export async function ApiGetOperations() {
    const { data } = await Service('get', '/operations');
    return data;
}

export async function ApiAddNewOrder(orderData) {
    const { data } = await Service('post', `/orders`, orderData, { 'Content-Type': 'application/json' });
    return data;
}

export async function ApiDeleteOrder(orderId) {
    const data = await Service('delete', '/orders', orderId);
    return data;
}

export async function ApiRegistration(userData) {
    const data = await Service('post', '/register', userData);
    return data;
}

export async function ApiGetUsers() {
    const { data } = await Service('get', '/users');
    return data;
}

export async function ApiSetLimit(limitData) {
    const data = await Service('put', '/limitUser', limitData)
    return data;
}

export async function ApiRecoveryPass(email) {
    const data = await Service('post', '/password/recovery', email);
    return data;
}

export async function ApiResetPass(passData) {
    const data = await Service('put', '/password/reset', passData);
    return data;
}

export async function ApiBlockUser(userId) {
    const data = await Service('put', '/users', {id: userId, status: 0});
    return data;
}

export async function ApiUnlockUser(userId) {
    const data = await Service('put', '/users', {id: userId, status: 1});
    return data;
}

export async function ApiRequestCall(userData) {
    const data = await Service('post', '/backCall', userData);
    return data;
}

export async function ApiAddOperation(operationData) {
    const data = await Service('post', '/operations', operationData);
    return data;
}

export async function ApiRequestFinancing(orderData) {
    const data = await Service('put', '/requestFinancing', orderData);
    return data;
}