import { takeEvery, put } from "redux-saga/effects";
import { SHOPS } from "../../reducers/shopsReducer";
import { APP } from "../../reducers/appReducer";
import { ApiGetShops, ApiSaveEditShopData, ApiAddNewShop, ApiDeleteShop } from "../../services/ApiCalls";
import { showMessage } from "../../utility/functions";

function* getShops() {
  try {
    const { data } = yield ApiGetShops();
    yield put({
      type: SHOPS.GET_SHOPS_SUCCESS,
      payload: data
    })
    yield put({
      type: APP.SET_SHOPS_LOADING_FALSE
    });
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* addNewShop(action) {
  try {
    yield ApiAddNewShop(action.payload);
    showMessage('success', 'Магазин добавлен');
    yield put({
      type: APP.SET_SHOPS_LOADING_TRUE
    })
    const { data } = yield ApiGetShops();
    yield put({
      type: SHOPS.GET_SHOPS_SUCCESS,
      payload: data
    });
    yield put({
      type: APP.SET_SHOPS_LOADING_FALSE
    })
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* saveEditShopData(action) {
  try {
    yield ApiSaveEditShopData(action.payload);
    showMessage('success', 'Данные сохранены');
    yield put({
      type: APP.SET_SHOPS_LOADING_TRUE
    })
    const { data } = yield ApiGetShops();
    yield put({
      type: SHOPS.GET_SHOPS_SUCCESS,
      payload: data
    });
    yield put({
      type: APP.SET_SHOPS_LOADING_FALSE
    })
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* deleteShop(action) {
  try {
    yield ApiDeleteShop(action.payload);
    showMessage('success', 'Магазин удален');
    yield put({
      type: APP.SET_SHOPS_LOADING_TRUE
    })
    const { data } = yield ApiGetShops();
    yield put({
      type: SHOPS.GET_SHOPS_SUCCESS,
      payload: data
    });
    yield put({
      type: APP.SET_SHOPS_LOADING_FALSE
    })
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

export default function* shopsSaga() {
  yield takeEvery(SHOPS.GET_SHOPS_REQUEST, getShops);
  yield takeEvery(SHOPS.SAVE_EDIT_DATA_REQUEST, saveEditShopData);
  yield takeEvery(SHOPS.ADD_NEW_SHOP_REQUEST, addNewShop);
  yield takeEvery(SHOPS.DELETE_SHOP_REQUEST, deleteShop);
}