import React from 'react';
import { setEditablePartner, setAccountToTransfer } from "../../actions/app";
import { deletePartner } from "../../actions/partners";
import { connect } from "react-redux";

const PartnersListItemComponent = props => {
  const { elem, indexInArr, setEditablePartner, deletePartner, setAccountToTransfer } = props;
  let numberOnPage = indexInArr + 1;
  const { id, agentName, agentEmail } = elem;

  return (
    <div className="Shops__item" data-key={id} data-type='partner'>
      <span className="Shops__item_number">{numberOnPage <= 9 ? `0${numberOnPage}` : numberOnPage}</span>
      <p className="Shops__item_name" >{agentName}</p>
      <p className="Shops__item_link" >{agentEmail}</p>
      <div className="Shops__item__icons">
        <div className="Shops__item__icons_transfer" onClick={event => {
          const type = event.currentTarget.parentElement.parentElement.getAttribute('data-type');
          const id = event.currentTarget.parentElement.parentElement.getAttribute('data-key');
          setAccountToTransfer(type, id);
        }} title="Перевести" >
          <svg  viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6897 23H12.4828V20.3529H17.8621V18.5882H12.4828V16.8235H16.5172C18.989 16.8235 21 14.8444 21 12.4118C21 9.97912 18.989 8 16.5172 8H11.5862C11.3484 8 11.1204 8.09296 10.9522 8.25844C10.7841 8.42391 10.6897 8.64834 10.6897 8.88235V15.0588H8V16.8235H10.6897V18.5882H8V20.3529H10.6897V23ZM12.4828 9.76471H16.5172C18.0001 9.76471 19.2069 10.9524 19.2069 12.4118C19.2069 13.8712 18.0001 15.0588 16.5172 15.0588H12.4828V9.76471Z" fill="black" />
            <path d="M13 28L7.0832 21.6761L4.56496 29.9622L13 28ZM6.95792 25.4132C3.83245 23.3304 1.75 19.6744 1.75 15.5H0.25C0.25 20.1795 2.58611 24.3024 6.12611 26.6615L6.95792 25.4132ZM1.75 15.5C1.75 8.9815 6.81536 3.75 13 3.75V2.25C5.92981 2.25 0.25 8.21138 0.25 15.5H1.75Z" fill="black" />
            <path d="M15 3L21.1755 9.07148L23.3458 0.687571L15 3ZM21.168 5.34588C24.8166 7.38975 27.25 11.1793 27.25 15.5H28.75C28.75 10.5946 25.9857 6.32534 21.9011 4.03722L21.168 5.34588ZM27.25 15.5C27.25 21.9619 21.7935 27.25 15 27.25V28.75C22.5659 28.75 28.75 22.8452 28.75 15.5H27.25Z" fill="black" />
          </svg>
        </div>
        <div className="Shops__item__icons_edit" title="Редактировать" onClick={() => setEditablePartner(id)}>
          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 19.9994V24.9994H5L19.7467 10.2528L14.7467 5.25276L0 19.9994ZM23.6133 6.38609C24.1333 5.86609 24.1333 5.02609 23.6133 4.50609L20.4933 1.38609C19.9733 0.866094 19.1333 0.866094 18.6133 1.38609L16.1733 3.82609L21.1733 8.82609L23.6133 6.38609Z" fill="black" />
          </svg>
        </div>
        <div className="Shops__item__icons_delete" title="Удалить" onClick={() => deletePartner({ 'id': id })} >
          <svg viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M1.35714 21.3333C1.35714 22.8 2.57857 24 4.07143 24H14.9286C16.4214 24 17.6429 22.8 17.6429 21.3333V5.33333H1.35714V21.3333ZM19 1.33333H14.25L12.8929 0H6.10714L4.75 1.33333H0V4H19V1.33333Z" fill="black" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export const PartnersListItem = connect(() => ({}), { setEditablePartner, deletePartner, setAccountToTransfer })(PartnersListItemComponent)