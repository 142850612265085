import React from 'react';
import { connect } from 'react-redux';
import { addNewOrder } from "../../actions/orders";
import { useFormik } from "formik";
import * as Yup from "yup";


function AddNewOrder(props) {
  const { shops, userId, addNewOrder, toggleVisibleAddOrderComponent } = props;
  const shema = Yup.object().shape({
    shop_id: Yup.string().required('Выберите магазин'),
    type_id: Yup.string().required('Выберите тип платежа'),
    delivery_id: Yup.string().required('Выберите Службу доставки'),
    total: Yup.number().required('Введите сумму заказа'),
  });
  const formik = useFormik({
    initialValues: {
      shop_id: '',
      type_id: '',
      delivery_id: '',
      total: '',
    },
    validationSchema: shema,
    onSubmit: values => {
      addNewOrder({ ...values, user_id: userId });
      toggleVisibleAddOrderComponent(false);
    }
  });
  const { touched, errors, handleSubmit, handleChange, values } = formik;

  return (
    <form onSubmit={handleSubmit} className="AddNewOrder">
      <div className="AddNewOrder__wrapper modal__select__wrapper">
        <select
          className={`AddNewOrder__select modal__select ${touched.shop_id && errors.shop_id && 'invalid'}`}
          name='shop_id'
          onChange={handleChange}
          value={values.shop_id}
        >
          <option className='AddNewOrder__option' value=''>Выберите магазин</option>
          {shops.map((elem, index) =>
            <option className='AddNewOrder__option' value={elem.id} key={index} >
              {elem.shopName}
            </option>)}
        </select>
        {touched.shop_id && errors.shop_id && <p className="AddNewOrder__invalid-message">{errors.shop_id}</p>}
      </div>
      <div className='AddNewOrder__wrapper modal__select__wrapper'>
        <select
          className={`AddNewOrder__select modal__select ${touched.type_id && errors.type_id && 'invalid'}`}
          name="type_id"
          onChange={handleChange}
          value={values.type_id}
        >
          <option className='AddNewOrder__option' value=''>Выберите тип платежа</option>
          <option className='AddNewOrder__option' value='1'>Наложенный платеж</option>
          <option className='AddNewOrder__option' value='2'>Предоплата</option>
        </select>
        {touched.type_id && errors.type_id && <p className="AddNewOrder__invalid-message">{errors.type_id}</p>}
      </div>
      <div className='AddNewOrder__wrapper modal__select__wrapper'>
        <select
          className={`AddNewOrder__select modal__select ${touched.delivery_id && errors.delivery_id && 'invalid'}`}
          name="delivery_id"
          onChange={handleChange}
          value={values.delivery_id}
        >
          <option className='AddNewOrder__option' value=''>Выберите Службу доставки</option>
          <option className='AddNewOrder__option' value='1'>CDEK</option>
          <option className='AddNewOrder__option' value='2'>Express</option>
        </select>
        {touched.delivery_id && errors.delivery_id && <p className="AddNewOrder__invalid-message">{errors.delivery_id}</p>}
      </div>
      <div className="AddNewOrder__wrapper">
        <input
          className={`AddNewOrder__select ${touched.total && errors.total && 'invalid'}`}
          type="number"
          placeholder='Введите сумму заказа'
          name="total"
          onChange={handleChange}
          value={values.total}
        />
        {touched.total && errors.total && <p className="AddNewOrder__invalid-message">{errors.total}</p>}
      </div>
      <button className='AddNewOrder__btn' type='submit' >Добавить заказ</button>
    </form >
  )
}

const mapStateToProps = store => ({
  shops: store.shops,
  userId: store.profile.id
});

export default connect(mapStateToProps, { addNewOrder })(AddNewOrder);