import { all } from 'redux-saga/effects';
import profileSaga from './profile/saga';
import shopsSaga from "./shops/saga";
import ordersSaga from "./orders/saga";
import appSaga from "./app/saga";
import partnersSaga from "./partners/saga";
import operationsSaga from "./operations/saga";
import usersSaga from "./users/saga";

export default function* rootSaga() {
  yield all([
    profileSaga(),
    shopsSaga(),
    ordersSaga(),
    appSaga(),
    partnersSaga(),
    operationsSaga(),
    usersSaga(),
  ]);
}