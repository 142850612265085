import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { hasToken } from '../../utility/functions';
import { history } from "../../index";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (hasToken()) {
        if(localStorage.isAdmin) {
          history.replace('/adminPanel');
        } else {
          return <Component {...props} />
        }
      } else {
        return <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      };
    }}
  />
);
