import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { getOrders } from "../../actions/orders";
import { getShops } from "../../actions/shops";

import Filter from "./FilterOrders";
import Table from "./TableWithOrdersList";

import './Orders.css';

export const Orders = props => {
  useEffect(() => {
    if(props.statusOfAuthorization) {
      props.getOrders();
      props.getShops();
    }
  })

  return (
    <div className="Orders">
      <div className="container">
        <h1 className="Pagetitle">Заказы</h1>
        <Filter />
        <Table />
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  statusOfAuthorization: store.app.statusOfAuthorization,
});

export default connect(mapStateToProps, {getOrders, getShops})(Orders)