import React from 'react';
import { formatValue } from "../../utility/functions";

export const TableRow = props => {
  const { elem, deleteOrder } = props;
  return (
    <tr key={elem.id} data-key={elem.id}>
      <td>{elem.created_at}</td>
      <td>{formatValue(elem.total) + ' руб.'}</td>
      <td>{elem.typeName}</td>
      <td>{elem.shopName}</td>
      <td className='td-last-on-row'>
        {elem.deliveryName}
        <div className="delete-btn" title="Удалить заказ" onClick={event => {
          const id = event.target.parentElement.parentElement.getAttribute('data-key');
          deleteOrder(id);
        }}></div>
      </td>
    </tr>
  )
}