export const SHOPS = {
  GET_SHOPS_REQUEST: 'GET_SHOPS_REQUEST',
  GET_SHOPS_SUCCESS: 'GET_SHOPS_SUCCESS',
  ADD_NEW_SHOP_REQUEST: 'ADD_NEW_SHOP_REQUEST',
  SAVE_EDIT_DATA_REQUEST: 'SAVE_EDIT_DATA_REQUEST',
  DELETE_SHOP_REQUEST: 'DELETE_SHOP_REQUEST',
}

const initialState = [];

export default function shopsReducer(state = initialState, action) {
  switch (action.type) {
    case SHOPS.RESET_STATE_SUCCESS:
      return [];
    case SHOPS.GET_SHOPS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}