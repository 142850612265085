import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { getRegistration } from "../../actions/app";

export const RegistrationPageForm = props => {
  const [agreenment, toggleAgreenment] = useState(true);
  const [visiblePasswordError, toggleVisiblePasswordError] = useState(false);
  const [visibleAgreenmentError, toggleVisibleAgreenmentError] = useState(false);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Введите почту')
      .email('Введите почту')
      .min(3, 'Минимальная длина 3 символа'),
    password: Yup.string()
      .min(6, 'Минимальная длина 6 символов')
      .required('Введите пароль'),
    phone: Yup.number()
      .required('Введите телефон')
      .positive()
      .typeError('Поле должно содержать только цифры')
      .min(99999, 'телефон не может быть короче 6 символов')
      .max(100000000000, 'телефон не может быть длиннее 11 символов'),

  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
      phone: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      if (!agreenment) {
        toggleVisibleAgreenmentError(true);
        return false;
      } else {
        toggleVisibleAgreenmentError(false);
      };
      if (values.password !== values.repeatPassword) {
        toggleVisiblePasswordError(true);
        return false
      } else {
        toggleVisiblePasswordError(false);
      };
      if (!visibleAgreenmentError && !visiblePasswordError) {
        props.getRegistration({ 
          email: values.email, 
          password: values.password, 
          telephone: values.phone 
        })
      }
    }
  });
  const { values, errors, handleSubmit, handleChange, touched } = formik;
  return (
    <form className="Login__form" onSubmit={handleSubmit}>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.email && errors.email) && 'Login__input-invalid'}`}
          type="text"
          name='email'
          placeholder="Email"
          value={values.email}
          onChange={handleChange}
        />
        {errors.email && touched.email && <div className='Login__invalid-message'>{errors.email}</div>}
      </div>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.password && errors.password) && 'Login__input-invalid'}`}
          type="password"
          name='password'
          placeholder="Пароль"
          value={values.password}
          onChange={handleChange}
        />
        {errors.password && touched.password && <div className='Login__invalid-message'>{errors.password}</div>}
      </div>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${visiblePasswordError && 'Login__input-invalid'}`}
          type="password"
          name='repeatPassword'
          placeholder="Повторите пароль"
          value={values.repeatPassword}
          onChange={(ev)=> {
            handleChange(ev);
            if(visiblePasswordError) {
              toggleVisiblePasswordError(false);
            }
          }}
        />
        {visiblePasswordError && <div className='Login__invalid-message'>Пароли не совпадают</div>}

      </div>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.phone && errors.phone) && 'Login__input-invalid'}`}
          type="text"
          name='phone'
          placeholder="Телефон"
          value={values.phone}
          onChange={handleChange}
        />
        {errors.phone && touched.phone && <div className='Login__invalid-message'>{errors.phone}</div>}
      </div>
      <div className="Login__row">
        {visibleAgreenmentError && <div className='Login__invalid-message'>Без согласия дальнейшая регистрация невозможна</div>}
        <label className="Login__label"  >
          <input
            className="Login__checkbox"
            type="checkbox"
            onClick={() => toggleAgreenment(!agreenment)}
            defaultChecked
          />
          Разрешаю обработку, хранение и любые <br /> операции с персональными данными
        </label>
      </div>
      <button className="Login__submitBtn" type="submit">Зарегистрироваться</button>
    </form>
  )
}

export default connect(() => ({}), { getRegistration })(RegistrationPageForm)