import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { formatValue } from "../../utility/functions";
import { getPartners } from "../../actions/partners";

import Partners from "./Partners";
import { Preloader } from "../Modals/Preloader";

const Financing = props => {
  const { balance, limit, getPartners, profileLoading } = props;
  useEffect(() => {
    getPartners();
  }, []);
  return (
    <div className="Financing">
      <div className="container">
        <div className="GetMoney__links-block">
          <Link to="/fastTransfers" className="GetMoney__passive-link">Ускоренные перечисления</Link>
          <p className="GetMoney__active-link">Целевое финансирование</p>
        </div>
        <h3 className="GetMoney__desc">Средства перечисляются контрагенту.</h3>
        <div className="GetMoney__white-block Financing__white-block">
          {profileLoading && <Preloader />}
          <div className="Financing__balance-info">
            <p className="Financing__balance-info_little-text">Текущая задолженность</p>
            <p className="Financing__balance-info_little-text">Доступный лимит для ускоренных перечислений</p>
            <p className="Financing__balance-info_big-text">{formatValue(balance)} рублей</p>
            <p className="Financing__balance-info_big-text">{formatValue(limit)} рублей</p>
          </div>
        </div>
        <Partners />
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  balance: store.profile.balance,
  limit: store.profile.limit,
  profileLoading: store.app.profileLoading
});


export default connect(mapStateToProps, { getPartners })(Financing);