import { successMessageModalRef } from "../components/Modals/SuccessMessageModal";
import { errorMessageModalRef } from "../components/Modals/ErrorMessageModal";

// принимает значение (5555555555) возвращает с пробелами через три знака (5 555 555 555)
export function formatValue(value) {
  let total = `${value}`.trim().split('').reverse();
  let newArr = [];
  for (let index = 0; index < total.length; index++) {
    newArr.push(total[index]);
    if ((index + 1) % 3 === 0) {
      newArr.push(' ');
    }
  };
  return newArr.reverse().join('')
}

// проверяет, есть ли токен
export function hasToken() {
  return localStorage.token || sessionStorage.token ? true : false;
}

// показывает модальное окно. Принимает ссылку на конкретное модальное окно, которое будет показано
export function showModal(elem) {
  if (document.documentElement.clientWidth > 480) {
    document.body.style.paddingRight = '17px';
  };
  document.body.style.overflow = 'hidden';
  elem.style.display = 'block';
  elem.parentElement.style.display = 'flex';
  setTimeout(() => {
    elem.parentElement.style.opacity = 1;
  }, 300)
}

// закрывает модальное окно, принимает:
// 1 событие (используется для идентификации источника события, чтобы не закрывать модалку при клике вне
// предназначенной области)
// 2 ссылку на конкретное модальное окно, которое будет закрыто
// 3 необязательный аргумент, если true, закрывает модалку вне зависимости от класса источника события
export function hideModal(event, elem, important = false) {
  const targetClass = important ? null : event.target.className;
  if (targetClass === 'modal_wrapper' || targetClass === 'modal__close-btn' || targetClass === 'modal__mobile-header_arrow' || important) {
    const modalWrapper = elem.parentElement;
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    modalWrapper.style.opacity = ''
    setTimeout(() => {
      modalWrapper.style.display = '';
      elem.style.display = '';
    }, 300)
  }
}

// устанавливает задержку
export function setDelay(ms) {
  return new Promise(r => setTimeout(r, ms));
}

// выводит сообщение о результате действия
export function showMessage(status, message, delay = 2500) {
  const targetModal = status === 'success' ? successMessageModalRef : errorMessageModalRef;
  targetModal.current.querySelector('.Status-message__message').textContent = message;
  targetModal.current.style.display = 'flex';
  setTimeout(() => targetModal.current.style.display = '', delay);
}

export function parseDate(dateString) {
  return new Date(`${dateString.substr(6, 4)}-${dateString.substr(3, 2)}-${dateString.substr(0, 2)}`);
}