import React from 'react';
import { Link } from "react-router-dom";
import './GetMoney.css'
import { connect } from 'react-redux';
import { formatValue } from "../../utility/functions";
import FastTransfersForm from "../Forms/FastTransfersForm";
import { Preloader } from "../Modals/Preloader";

const FastTransfers = props => {
  const { balance, limit, profileLoading } = props;

  return (
    <div className="FastTransfers">
      <div className="container">
        <div className="GetMoney__links-block">
          <p className="GetMoney__active-link">Заказать финансирование</p>
          {/* <Link to="/financing" className="GetMoney__passive-link">Целевое финансирование</Link> */}
        </div>
        <h3 className="GetMoney__desc">Средства перечисляются на ваш расчётный счёт.</h3>
        <div className="GetMoney__white-block">
          {/* <h3 className="FastTransfers__title">Заказать ускоренное перечисление</h3> */}
          <div className="FastTransfers__balance-info GetMoney__balance-info">
            {profileLoading && <Preloader />}
            <p className="FastTransfers__balance-info_little-text">Текущая задолженность</p>
            <p className="FastTransfers__balance-info_big-text">{formatValue(balance)} рублей</p>
            <p className="FastTransfers__balance-info_little-text">Доступный лимит финансирования</p>
            <p className="FastTransfers__balance-info_big-text">{formatValue(limit)} рублей</p>
          </div>
          <FastTransfersForm />
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  balance: store.profile.balance,
  limit: store.profile.limit,
  profileLoading: store.app.profileLoading,
});


export default connect(mapStateToProps)(FastTransfers)