import React from 'react';
import { Link } from "react-router-dom";

import RecoveryPassForm from "../Forms/RecoveryPassForm";

import "./Login.css";

export const RecoveryPass = () => {
  return (
    <>
      <div className="Login_wrapper Recovery">
        <div className=" Login">
          <h3 className="Login__title">Восстановление пароля</h3>
          <RecoveryPassForm />
          <p className="Recovery__little-text">Код для восстановления пароля придет на указанный Email</p>
          <div className="Recovery__links">
            <Link className='Login__registration-link' to='/login'>Войти</Link>
            <Link className='Login__registration-link' to='/registration'>Зарегистрироваться</Link>
          </div>
        </div>
      </div>
    </>
  )
}