export const APP = {
  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
  GET_AUTHORIZATION_REQUEST: 'GET_AUTHORIZATION_REQUEST',
  GET_AUTHORIZATION_SUCCESS: 'GET_AUTHORIZATION_SUCCESS',
  SET_EDITABLE_SHOP_REQUEST: 'SET_EDITABLE_SHOP_REQUEST',
  SET_EDITABLE_SHOP_SUCCESS: 'SET_EDITABLE_SHOP_SUCCESS',
  REMOVE_EDITABLE_SHOP_REQUEST: 'REMOVE_EDITABLE_SHOP_REQUEST',
  REMOVE_EDITABLE_SHOP_SUCCESS: 'REMOVE_EDITABLE_SHOP_SUCCESS',
  SET_EDITABLE_PARTNER_REQUEST: 'SET_EDITABLE_PARTNER_REQUEST',
  SET_EDITABLE_PARTNER_SUCCESS: 'SET_EDITABLE_PARTNER_SUCCESS',
  REMOVE_EDITABLE_PARTNER_REQUEST: 'REMOVE_EDITABLE_PARTNER_REQUEST',
  REMOVE_EDITABLE_PARTNER_SUCCESS: 'REMOVE_EDITABLE_PARTNER_SUCCESS',
  SET_PROFILE_LOADING_TRUE: 'SET_PROFILE_LOADING_TRUE',
  SET_PROFILE_LOADING_FALSE: 'SET_PROFILE_LOADING_FALSE',
  SET_SHOPS_LOADING_TRUE: 'SET_SHOPS_LOADING_TRUE',
  SET_SHOPS_LOADING_FALSE: 'SET_SHOPS_LOADING_FALSE',
  SET_ORDERS_LOADING_TRUE: 'SET_ORDERS_LOADING_TRUE',
  SET_ORDERS_LOADING_FALSE: 'SET_ORDERS_LOADING_FALSE',
  SET_PARTNERS_LOADING_TRUE: 'SET_PARTNERS_LOADING_TRUE',
  SET_PARTNERS_LOADING_FALSE: 'SET_PARTNERS_LOADING_FALSE',
  SET_OPERATIONS_LOADING_TRUE: 'SET_OPERATIONS_LOADING_TRUE',
  SET_OPERATIONS_LOADING_FALSE: 'SET_OPERATIONS_LOADING_FALSE',
  SET_ORDERS_FILTER_REQUEST: 'SET_ORDERS_FILTER_REQUEST',
  SET_ORDERS_FILTER_SUCCESS: 'SET_ORDERS_FILTER_SUCCESS',
  DELETE_ORDERS_FILTER_FIELD_REQUEST: 'DELETE_ORDERS_FILTER_FIELD_REQUEST',
  DELETE_ORDERS_FILTER_FIELD_SUCCESS: 'DELETE_ORDERS_FILTER_FIELD_SUCCESS',
  TOGGLE_VISIBLE_DROPDOWN_MENU_REQUEST: 'TOGGLE_VISIBLE_DROPDOWN_MENU_REQUEST',
  TOGGLE_VISIBLE_DROPDOWN_MENU_SUCCESS: 'TOGGLE_VISIBLE_DROPDOWN_MENU_SUCCESS',
  RESET_AUTHORIZATION_REQUEST: 'RESET_AUTHORIZATION_REQUEST',
  RESET_AUTHORIZATION_SUCCESS: 'RESET_AUTHORIZATION_SUCCESS',
  SET_ACCOUNT_TO_TRANSFER_REQUEST: "SET_ACCOUNT_TO_TRANSFER_REQUEST",
  SET_ACCOUNT_TO_TRANSFER_SUCCESS: "SET_ACCOUNT_TO_TRANSFER_SUCCESS",
  REMOVE_ACCOUNT_TO_TRANSFER_REQUEST: "REMOVE_ACCOUNT_TO_TRANSFER_REQUEST",
  REMOVE_ACCOUNT_TO_TRANSFER_SUCCESS: "REMOVE_ACCOUNT_TO_TRANSFER_SUCCESS",
  GET_REGISTRATION_REQUEST: "GET_REGISTRATION_REQUEST",
  SET_OPERATIONS_FILTER_REQUEST: 'SET_OPERATIONS_FILTER_REQUEST',
  SET_OPERATIONS_FILTER_SUCCESS: 'SET_OPERATIONS_FILTER_SUCCESS',
  DELETE_OPERATIONS_FILTER_FIELD_REQUEST: 'DELETE_OPERATIONS_FILTER_FIELD_REQUEST',
  DELETE_OPERATIONS_FILTER_FIELD_SUCCESS: 'DELETE_OPERATIONS_FILTER_FIELD_SUCCESS',
  RECOVERY_PASS_REQUEST: 'RECOVERY_PASS_REQUEST',
  RESER_PASS_REQUEST: 'RESER_PASS_REQUEST',
  REQUEST_CALL_REQUEST: 'REQUEST_CALL_REQUEST',
}

const initialState = {
  statusOfAuthorization: false,
  errors: {},
  profileLoading: true,
  shopsLoading: true,
  ordersLoading: true,
  partnersLoading: true,
  operationsLoading: true,
  editableShop: '',
  editablePartner: '',
  filterForOrders: {},
  filterForOperations: {},
  visibleDropDownMenu: false,
  accountToTransfer: '',
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case APP.REMOVE_ACCOUNT_TO_TRANSFER_SUCCESS:
      return {
        ...state,
        accountToTransfer: '',
      }
    case APP.SET_ACCOUNT_TO_TRANSFER_SUCCESS:
      return {
        ...state,
        accountToTransfer: action.payload,
      }
    case APP.RESET_AUTHORIZATION_SUCCESS:
      return {
        statusOfAuthorization: false,
        errors: {},
        profileLoading: true,
        shopsLoading: true,
        ordersLoading: true,
        partnersLoading: true,
        operationsLoading: true,
        visibleSuccessMessage: false,
        visibleErrorMessage: false,
        successMessage: '',
        errorMessage: '',
        editableShop: '',
        editablePartner: '',
        filterForOrders: {},
        visibleDropDownMenu: false,
        accountToTransfer: '',
      }
    case APP.TOGGLE_VISIBLE_DROPDOWN_MENU_SUCCESS:
      return {
        ...state,
        visibleDropDownMenu: !state.visibleDropDownMenu,
      }
    case APP.DELETE_OPERATIONS_FILTER_FIELD_SUCCESS: {
      const newObj = { ...state.filterForOperations };
      delete newObj[action.payload];
      return {
        ...state,
        filterForOperations: { ...newObj },
      }
    }
    case APP.SET_OPERATIONS_FILTER_SUCCESS:
      return {
        ...state,
        filterForOperations: action.payload,
      }
    case APP.DELETE_ORDERS_FILTER_FIELD_SUCCESS: {
      const newObj = { ...state.filterForOrders };
      delete newObj[action.payload];
      return {
        ...state,
        filterForOrders: { ...newObj },
      }
    }
    case APP.SET_ORDERS_FILTER_SUCCESS:
      return {
        ...state,
        filterForOrders: action.payload,
      }
    case APP.SET_SHOPS_LOADING_TRUE:
      return {
        ...state,
        shopsLoading: true,
      }
    case APP.SET_SHOPS_LOADING_FALSE:
      return {
        ...state,
        shopsLoading: false,
      }
    case APP.SET_ORDERS_LOADING_TRUE:
      return {
        ...state,
        ordersLoading: true,
      }
    case APP.SET_ORDERS_LOADING_FALSE:
      return {
        ...state,
        ordersLoading: false,
      }
    case APP.SET_PARTNERS_LOADING_TRUE:
      return {
        ...state,
        partnersLoading: true,
      }
    case APP.SET_PARTNERS_LOADING_FALSE:
      return {
        ...state,
        partnersLoading: false,
      }
    case APP.SET_OPERATIONS_LOADING_TRUE:
      return {
        ...state,
        operationsLoading: true,
      }
    case APP.SET_OPERATIONS_LOADING_FALSE:
      return {
        ...state,
        operationsLoading: false,
      }
    case APP.GET_PROFILE_REQUEST:
      return {
        ...state,
        errors: {},
        profileLoading: true,
      }
    case APP.GET_PROFILE_SUCCESS:
      return {
        ...state,
        statusOfAuthorization: true,
        errors: {},
        profileLoading: false
      };
    case APP.GET_PROFILE_ERROR:
      return {
        ...state,
        errors: action,
        profileLoading: false,
        statusOfAuthorization: false
      }
    case APP.GET_AUTHORIZATION_REQUEST:
      return {
        ...state,
        errors: {},
        profileLoading: true,
      }
    case APP.GET_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        statusOfAuthorization: true,
        errors: {},
        profileLoading: false
      };
    case APP.SET_EDITABLE_SHOP_SUCCESS:
      return {
        ...state,
        editableShop: action.payload,
      }
    case APP.REMOVE_EDITABLE_SHOP_SUCCESS:
      return {
        ...state,
        editableShop: ''
      }
    case APP.SET_EDITABLE_PARTNER_SUCCESS:
      return {
        ...state,
        editablePartner: action.payload,
      }
    case APP.REMOVE_EDITABLE_PARTNER_SUCCESS:
      return {
        ...state,
        editablePartner: ''
      }
    default:
      return state;
  }
}