import { takeEvery, put } from "redux-saga/effects";
import { PARTNERS } from "../../reducers/partnersRedusec";
import { APP } from "../../reducers/appReducer";
import { ApiGetPartners, ApiDeletePartner, ApiAddNewPartner, ApiSaveEditPartnerData } from "../../services/ApiCalls";
import { showMessage } from "../../utility/functions";

function* getPartners() {
  try {
    const { data } = yield ApiGetPartners();
    yield put({
      type: PARTNERS.GET_PARTNERS_SUCCESS,
      payload: data,
    });
    yield put({
      type: APP.SET_PARTNERS_LOADING_FALSE
    });
  } catch ({ response }) {
    yield showMessage('error', 'Произошла ошибка');
    yield console.error(response.data.message);
  }
}

function* addNewPartner(action) {
  try {
    yield ApiAddNewPartner(action.payload);
    yield showMessage('success', 'Контрагент добавлен');
    yield put({
      type: APP.SET_PARTNERS_LOADING_TRUE
    });
    const { data } = yield ApiGetPartners();
    yield put({
      type: PARTNERS.GET_PARTNERS_SUCCESS,
      payload: data,
    });
    yield put({
      type: APP.SET_PARTNERS_LOADING_FALSE
    });
  } catch ({ response }) {
    yield showMessage('error', 'Произошла ошибка');
    yield console.error(response.data.message);
  }
}

function* saveModifiedPartnerData(action) {
  try {
    yield ApiSaveEditPartnerData(action.payload);
    yield showMessage('success', 'Данные сохранены');
    yield put({
      type: PARTNERS.SAVE_MODIFIED_DATA_SUCCESS,
      payload: action.payload
    })
    yield put({
      type: APP.SET_PARTNERS_LOADING_TRUE
    });
    const { data } = yield ApiGetPartners();
    yield put({
      type: PARTNERS.GET_PARTNERS_SUCCESS,
      payload: data,
    });
    yield put({
      type: APP.SET_PARTNERS_LOADING_FALSE
    });
  } catch ({ response }) {
    yield showMessage('error', 'Произошла ошибка');
    yield console.error(response.data.message);
  }
}

function* deletePartner(action) {
  try {
    yield ApiDeletePartner(action.payload);
    yield showMessage('success', 'Контрагент удален');
    yield put({
      type: APP.SET_PARTNERS_LOADING_TRUE
    });
    const { data } = yield ApiGetPartners();
    yield put({
      type: PARTNERS.GET_PARTNERS_SUCCESS,
      payload: data,
    });
    yield put({
      type: APP.SET_PARTNERS_LOADING_FALSE
    });
  } catch ({ response }) {
    yield showMessage('error', 'Произошла ошибка');
    yield console.error(response.data.message);
  }
}

export default function* partnersSaga() {
  yield takeEvery(PARTNERS.GET_PARTNERS_REQUEST, getPartners);
  yield takeEvery(PARTNERS.ADD_NEW_PARTNER_REQUEST, addNewPartner);
  yield takeEvery(PARTNERS.SAVE_MODIFIED_DATA_REQUEST, saveModifiedPartnerData);
  yield takeEvery(PARTNERS.DELETE_PARTNER_REQUEST, deletePartner);
}