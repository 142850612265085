import {
  USERS
} from "../../reducers/usersReducer";
import {
  ApiGetUsers,
  ApiSetLimit,
  ApiBlockUser,
  ApiUnlockUser
} from "../../services/ApiCalls";
import {
  takeEvery,
  put
} from "redux-saga/effects";
import {
  showMessage
} from "../../utility/functions";

function* getUsers() {
  try {
    const {
      data
    } = yield ApiGetUsers();
    yield put({
      type: USERS.GET_USERS_SUCCESS,
      payload: data,
    });
  } catch ({
    response
  }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* setLimit(action) {
  try {
    yield ApiSetLimit(action.payload);
    yield showMessage('success', 'Лимит установлен');
    yield put({
      type: USERS.GET_USERS_REQUEST
    })
  } catch ({
    response
  }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* blockUser(action) {
  try {
    const {
      data
    } = yield ApiBlockUser(action.payload);
    yield put({
      type: USERS.BLOCK_USER_SUCCESS,
      payload: action.payload,
    });
    showMessage('success', data.response)
  } catch ({
    response
  }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* unlockUser(action) {
  try {
    const {
      data
    } = yield ApiUnlockUser(action.payload);
    yield put({
      type: USERS.UNLOCK_USER_SUCCESS,
      payload: action.payload,
    });
    showMessage('success', data.response)
  } catch ({
    response
  }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

export default function* usersSaga() {
  yield takeEvery(USERS.GET_USERS_REQUEST, getUsers);
  yield takeEvery(USERS.SET_LIMIT_REQUEST, setLimit);
  yield takeEvery(USERS.BLOCK_USER_REQUEST, blockUser);
  yield takeEvery(USERS.UNLOCK_USER_REQUEST, unlockUser);
}