import React, {createRef} from 'react';
import { hideModal } from "../../utility/functions";
import Logo_mobile from "../../img/logo_mob.jpg";
import { resetAuthorization } from "../../actions/app";
import { connect } from "react-redux";

export const ConfirmExitRef = createRef();

function ConfirmExit(props) {
  const {resetAuthorization} = props;
  return (
    <div className="modal_wrapper" 
    onClick={event => hideModal(event, ConfirmExitRef.current)}>
      <div className="modal Confirm" ref={ConfirmExitRef}>
        <div className="modal__mobile-header">
          <a href="#link">
            <img src={Logo_mobile} alt="logo" className="modal__mobile-header_logo" />
          </a>
          <div className="modal__mobile-header_arrow" onClick={event => {
            event.stopPropagation();
            hideModal(event, ConfirmExitRef.current)
          }}>
            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z"
                fill="#0B3043" />
            </svg>
          </div>
        </div>
        <div className="modal__close-btn" onClick={event => {
          event.stopPropagation();
          hideModal(event, ConfirmExitRef.current);
        }}></div>
        <p className="Confirm__dsc">Вы точно хотите выйти?</p>
        <div className="Confirm__buttons-block">
          <button className="Confirm__button Confirm__button-exit" onClick={resetAuthorization}>Выйти</button>
          <button className="Confirm__button Confirm__button-return" onClick={()=> hideModal(null, ConfirmExitRef.current, true)}>Отмена</button>
        </div>
      </div>
    </div>
  )
}

export default connect(null, {resetAuthorization})(ConfirmExit)