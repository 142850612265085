import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { fastTransferMoney } from "../../actions/profile";
import { getShops } from "../../actions/shops/index";
import { store } from "../../index";


const FastTransfersForm = props => {
  const { limit, getShops, fastTransferMoney } = props;
  useEffect(() => {
    getShops();
  }, []);
  const [visibleOptions, toggleVisibleOptions] = useState(false);
  const [visibleError, toggleVisibleError] = useState(false);
  const [accountEntered, toggleAccountEntered] = useState(false);
  const [shopId, setShopId] = useState(0);
  const inputNumberRef = useRef();
  const shema = Yup.object().shape({
    inputValue: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .positive('Число должно быть положительным')
      .required("Введите сумму перевода")
      .max(limit, 'Сумма не может превышать лимит')
      .min(1, 'Минимальная сумма перевода 1 рубль')
      .integer('Сумма перевода должна быть целым числом')
  });
  const formik = useFormik({
    initialValues: {
      inputValue: '',
    },
    validationSchema: shema,
    onSubmit: values => {
      if (accountEntered) {
        fastTransferMoney({
          shopId: shopId,
          value: +values.inputValue,
        });
      } else {
        toggleVisibleError(true);
      }
    },
  })
  const { touched, errors, handleSubmit, handleChange, values } = formik;
  const templateOfSelectOptions = [];

  // подготавливаем для рендера название магазинов и рассчетные счета для переводов
  const typesOfOrganization = [];
  const shops = [];
  const bankRashAccounts = [];

  store.getState().shops.forEach(shop => {
    bankRashAccounts.push(shop.bankRashAccount);
    typesOfOrganization.push(shop.typeOfOrganization);
    shops.push({ shopName: shop.shopName, shopId: shop.id });
  });

  for (let index = 0; index < shops.length; index++) {
    templateOfSelectOptions.push(<p className="FastTransfers__form__option" onClick={event => {
      inputNumberRef.current.textContent = event.target.textContent;
      toggleVisibleOptions(!visibleOptions);
      toggleAccountEntered(true);
      setShopId(event.target.getAttribute('datashopid'));
    }} key={index} datashopid={shops[index].shopId}>{`${typesOfOrganization[index]} "${shops[index].shopName}" р/с ${bankRashAccounts[index]}`}</p>)
  }

  return (
    <form className="FastTransfers__form" onSubmit={handleSubmit}>
      <p className="FastTransfers__form__input-title">Сумма перевода</p>
      <div className="FastTransfers__form__wrapper">
        <input
          type="text"
          className={`FastTransfers__form__input ${errors.inputValue && touched.inputValue && 'invalid'}`}
          placeholder="&#8381;"
          onChange={handleChange}
          name="inputValue"
          value={values.inputValue}
        />
        {errors.inputValue && touched.inputValue && <div className='invalid-message'>{errors.inputValue}</div>}
      </div>
      <p className="FastTransfers__form__input-title">Номер счёта</p>
      <div className="FastTransfers__form__wrapper">
        <div className={`FastTransfers__form__input FastTransfers__form__select ${visibleError && 'invalid'}`} onClick={() => {
          toggleVisibleOptions(!visibleOptions);
          toggleVisibleError(false);
        }}>
          <p className="FastTransfers__form__select_value" ref={inputNumberRef}></p>
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="#C4C4C4" />
          </svg>
          {visibleError && <div className='invalid-message FastTransfers__form__invalid-message'>Выберите номер счета</div>}
        </div>
        {visibleOptions && <div className="FastTransfers__form__options-block">
            {templateOfSelectOptions.length ? templateOfSelectOptions : <span>Необходимо добавить интернет-магазин</span>}
          </div>}
      </div>
      <button className="FastTransfers__form__submitBtn" type="submit">Перевести</button>
    </form>
  )
};

const mapStateToProps = store => ({
  limit: store.profile.limit,
});

export default connect(mapStateToProps, { fastTransferMoney, getShops })(FastTransfersForm)