import React from 'react';
import { connect } from "react-redux";
import { deleteShop } from "../../actions/shops";
import { setEditableShop } from "../../actions/app";

export const ShopsListItemComponent = props => {

  const { elem, indexInArr, setEditableShop, deleteShop } = props;
  let numberOnPage = indexInArr + 1;
  const { id, shopName, shopLink } = elem;

  return (
    <div className="Shops__item" data-key={id}>
      <span className="Shops__item_number">{numberOnPage <= 9 ? `0${numberOnPage}` : numberOnPage}</span>
      <p className="Shops__item_name" >{shopName}</p>
      <a href={shopLink} target='_blank' rel="noopener noreferrer" className="Shops__item_link">{shopLink}</a>
      <div className="Shops__item__icons">
        <div className="Shops__item__icons_edit" title="Редактировать" onClick={() => {
          setEditableShop(id);
        }}>
          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 19.9994V24.9994H5L19.7467 10.2528L14.7467 5.25276L0 19.9994ZM23.6133 6.38609C24.1333 5.86609 24.1333 5.02609 23.6133 4.50609L20.4933 1.38609C19.9733 0.866094 19.1333 0.866094 18.6133 1.38609L16.1733 3.82609L21.1733 8.82609L23.6133 6.38609Z" fill="black" />
          </svg>
        </div>
        <div className="Shops__item__icons_delete" title="Удалить" onClick={() => deleteShop({ 'id': id })}>
          <svg viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M1.35714 21.3333C1.35714 22.8 2.57857 24 4.07143 24H14.9286C16.4214 24 17.6429 22.8 17.6429 21.3333V5.33333H1.35714V21.3333ZM19 1.33333H14.25L12.8929 0H6.10714L4.75 1.33333H0V4H19V1.33333Z" fill="black" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export const ShopsListItem = connect(() => ({}), { deleteShop, setEditableShop })(ShopsListItemComponent)