export const ORDERS = {
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  ADD_NEW_ORDER_REQUEST: 'ADD_NEW_ORDER_REQUEST',
  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
}

const initialState = [];

export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case ORDERS.RESET_STATE_SUCCESS:
      return [];
    case ORDERS.GET_ORDERS_SUCCESS:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
}