import React, {createRef} from 'react';

export const errorMessageModalRef = createRef();

export const ErrorMessageModal = () => {
  return (
    <div className="Status-message Status-message__error" ref={errorMessageModalRef}>
      <div className="Status-message__icon Status-message__icon-error">
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="37" cy="37" r="36.5" stroke="#DF402D" />
          <path d="M24 24L51 51" stroke="#DF402D" strokeWidth="8" strokeLinecap="round" />
          <path d="M24 51L51 24" stroke="#DF402D" strokeWidth="8" strokeLinecap="round" />
        </svg>
      </div>
      <p className="Status-message__message"></p>
    </div>
  )
}