import { USERS } from "../../reducers/usersReducer";

export function getUsers() {
  return {
    type: USERS.GET_USERS_REQUEST
  }
}

export function setLimit(limitData) {
  return {
    type: USERS.SET_LIMIT_REQUEST,
    payload: limitData
  }
}

export function blockUser(userId) {
  return { 
    type: USERS.BLOCK_USER_REQUEST,
    payload: userId
  };
}
export function unlockUser(userId) {
  return { 
    type: USERS.UNLOCK_USER_REQUEST,
    payload: userId
  };
}