import React from 'react';
import { Link } from "react-router-dom";
import { showModal } from "../../utility/functions";
import { RequestCalRef } from "../Modals/RequestCallModal";

import logo from "../../img/logoheader.png";
import asapLogo from "../../img/asap_logo.png";
import asapDesc from "../../img/asap_desc.png";

import "./Footer.css";

export const Footer = () => {
  return (
    <div className="Footer">
      <div className="container">
        <div className="Footer__wrapper">
          <a href="/" className="Footer__logo">
            <img src={logo} alt="logo" />
          </a>
          <nav className="Footer__menu">
            <Link to="/fastTransfers" className="Footer__menu__item">Получить деньги</Link>
            {/* <a href="#link" className="Footer__menu__item" onClick={event=> event.preventDefault()}>Вернуть деньги</a> */}
            <Link to="/operations" className="Footer__menu__item">Операции</Link>
            {/* <a href="#link" className="Footer__menu__item" onClick={event=> event.preventDefault()}>Выписки</a> */}
            <Link to="/orders" className="Footer__menu__item">Заказы</Link>
            {/* <a href="#link" className="Footer__menu__item" onClick={event=> event.preventDefault()}>Поддержка</a> */}
          </nav>
          <div className="Footer__contacts">
            <a href="tel:+78452398188" className="Footer__contacts__tel">+7 (8452) 398-188</a>
            {/* <a href="#link" className="Footer__contacts__requestCall">ЗАКАЗАТЬ ЗВОНОК</a> */}
            <div className="Footer__contacts__requestCall" onClick={()=> showModal(RequestCalRef.current)}>ЗАКАЗАТЬ ЗВОНОК</div>
          </div>
        </div>
        <div className="Footer__wrapper">
          <p className="Footer__copyright">© 2018 Progress app</p>
          <a href="https://asap-ag.ru/" target='_blank' rel="noopener noreferrer" className="Footer__asap">
            <img className="Footer__asap__logo" src={asapLogo} alt="asap-logo" />
            <img src={asapDesc} alt="Разработка сайта digital-агенство ASAP" className="Footer__asap__desc" />
          </a>
        </div>
      </div>
    </div>
  );
}