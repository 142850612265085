import React, { useState, useRef } from 'react';
import { connect } from "react-redux";
import { formatValue } from "../../utility/functions";
import { setNewPassword } from "../../actions/profile";

import InfoForm from "../Forms/InfoForm";
import { Preloader } from "../../components/Modals/Preloader";

const Info = props => {
  const { balance, limit, email } = props.profile;
  const { setNewPassword, profileLoading } = props;
  const [invalidPass, toggleValidPass] = useState(false);
  const [passError, setPassError] = useState('');
  const passFieldRef = useRef();
  const repeatPassFieldRef = useRef();

  function changePassword() {
    const passwordFieldValue = passFieldRef.current.value.trim();
    const repeatPasswordFieldValue = repeatPassFieldRef.current.value.trim();
    if (passwordFieldValue.length > 0 && passwordFieldValue.length < 6) {
      toggleValidPass(true);
      setPassError('Пароль не может быть короче 6 символов')
    } else if (passwordFieldValue.length > 0 && passwordFieldValue.length >= 6) {
      if (passwordFieldValue === repeatPasswordFieldValue) {
        toggleValidPass(false);
        setNewPassword({ password: passwordFieldValue });
        passFieldRef.current.value = '';
        repeatPassFieldRef.current.value = '';
      } else {
        toggleValidPass(true);
        setPassError('Пароли не совпадают')
      }
    } else {
      toggleValidPass(false);
    }
  }

  return (
    <div className="Info">
      {profileLoading && <Preloader />}
      <div className="Info__result-message" ></div>
      <div className="Info__balance Info__info">
        <div className="Info__balance__row">
          <p className="Info__balance__text">Текущая задолженность</p>
          <p className="Info__balance__value">{formatValue(balance)} рублей </p>
        </div>
        <div className="Info__balance__row">
          <p className="Info__balance__text">Доступный лимит финансирования</p>
          <p className="Info__balance__value">{formatValue(limit)} рублей</p>
        </div>
      </div>
      <div className="Info__info">
        <p className="Info__info__title">Общая информация</p>
        {email && <InfoForm />}
      </div>
      <div className="Info__info">
        <p className="Info__info__title">Пароль</p>
        <form>
          <div className="Info__info__wrapper">
            <input
              type="password"
              className={`Info__info__input ${invalidPass && 'Info__info__input-invalid'}`}
              placeholder='Пароль'
              ref={passFieldRef}
            />
            {invalidPass && <div className='invalid-message invalid-message-info'>{passError}</div>}
          </div>
          <div className="Info__info__wrapper">
            <input
              type="password"
              className={`Info__info__input ${invalidPass && 'Info__info__input-invalid'}`}
              placeholder='Повторите пароль'
              ref={repeatPassFieldRef}
            />
            {invalidPass && <div className='invalid-message invalid-message-info'>{passError}</div>}
          </div>
        </form>
      </div>
      <div className="Info__saveBtn-wrapper">
        <button className="Info__saveBtn" type="submit" form="profileData" onClick={changePassword}>Сохранить</button>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  profile: store.profile,
  profileLoading: store.app.profileLoading,
})


export default connect(mapStateToProps, { setNewPassword })(Info)