import React from 'react';

export const TypesOfPayments = props => {
  const { selectPayment, typesOfPayments, toggleVisiblePaymentsOptions } = props;
  return (
    <div className="Filter__form__select__options-block">
      {typesOfPayments.map((elem, index) => <p className="Filter__form__select_option" key={index + 1} onClick={ev => {
        selectPayment(ev.target.textContent);
        toggleVisiblePaymentsOptions(false);
      }}>{elem}</p>)}
    </div>
  )
}
