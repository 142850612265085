import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import orders from "./ordersReducer";
import profile from "./profileReducer";
import shops from "./shopsReducer";
import app from "./appReducer";
import partners from "./partnersRedusec";
import operations from "./operationsReducer";
import users from "./usersReducer";

export default combineReducers({
  routerReducer,
  orders,
  shops,
  profile,
  app,
  partners,
  operations,
  users,
})