import {APP} from '../../reducers/appReducer';

export function getProfile () {
  return {
    type: APP.GET_PROFILE_REQUEST
  }
}

export function getAuthorization (authorizeData, remember, callbackIfFailed) {
  return {
    type: APP.GET_AUTHORIZATION_REQUEST,
    payload: authorizeData,
    remember,
    callbackIfFailed
  }
}

export function setEditableShop(shopId) {
  return {
    type: APP.SET_EDITABLE_SHOP_REQUEST,
    payload: shopId
  }
}

export function removeEditableShop() {
  return {
    type: APP.REMOVE_EDITABLE_SHOP_REQUEST
  }
}

export function setEditablePartner(partnerId) {
  return {
    type: APP.SET_EDITABLE_PARTNER_REQUEST,
    payload: partnerId
  }
}

export function removeEditablePartner() {
  return {
    type: APP.REMOVE_EDITABLE_PARTNER_REQUEST
  }
}

export function setOrdersFilter(data) {
  return {
    type: APP.SET_ORDERS_FILTER_REQUEST,
    payload: data,
  }
}

export function deleteFieldInOrdersFilter(fieldName) {
  return {
    type: APP.DELETE_ORDERS_FILTER_FIELD_REQUEST,
    payload: fieldName
  }
}

export function toggleVisibleDropDownMenu() {
  return {
    type: APP.TOGGLE_VISIBLE_DROPDOWN_MENU_REQUEST,
  }
}

export function resetAuthorization() {
  return {
    type: APP.RESET_AUTHORIZATION_REQUEST
  }
}

export function setAccountToTransfer(type, id) {
  return {
    type: APP.SET_ACCOUNT_TO_TRANSFER_REQUEST,
    payload: {type: type, id: id},
  }
}

export function removeAccountToTransfer() {
  return {
    type: APP.REMOVE_ACCOUNT_TO_TRANSFER_REQUEST
  }
}

export function getRegistration(userData) {
  return {
    type: APP.GET_REGISTRATION_REQUEST,
    payload: userData
  }
}

export function setOperationsFilter(data) {
  return {
    type: APP.SET_OPERATIONS_FILTER_REQUEST,
    payload: data,
  }
}

export function deleteFieldInOperationsFilter(fieldName) {
  return {
    type: APP.DELETE_OPERATIONS_FILTER_FIELD_REQUEST,
    payload: fieldName
  }
}

export function recoveryPass(email) {
  return { 
    type: APP.RECOVERY_PASS_REQUEST,
    payload: email
  };
}

export function resetPass(passData) {
  return { 
    type: APP.RESER_PASS_REQUEST,
    payload: passData
  };
}

export function requestCall(userData) {
  return { 
    type: APP.REQUEST_CALL_REQUEST,
    payload: userData
  };
}