import { PROFILE } from "../../reducers/profileReducer";

export function setUserData(userData) {
  return {
    type: PROFILE.ADD_USER_DATA_REQUEST,
    payload: userData
  }
}
export function setNewPassword(newPassword) {
  return {
    type: PROFILE.SET_NEW_PASSWORD_REQUEST,
    payload: newPassword
  }
}

export function fastTransferMoney(value) {
  return {
    type: PROFILE.FAST_TRANSFER_MONEY_REQUEST,
    payload: value
  }
}

export function transferToPartner(transferData) {
  return {
    type: PROFILE.TRANSFER_MONEY_TO_PARTNER_REQUEST,
    payload: transferData
  }
}