import { SHOPS } from "../../reducers/shopsReducer";

export function getShops() {
  return {
    type: SHOPS.GET_SHOPS_REQUEST
  }
}

export function saveEditShopData(shopData) {
  return {
    type: SHOPS.SAVE_EDIT_DATA_REQUEST,
    payload: shopData
  }
}

export function addNewShop(shopData) {
  return {
    type: SHOPS.ADD_NEW_SHOP_REQUEST,
    payload: shopData
  }
}

export function deleteShop(shopId) {
  return {
    type: SHOPS.DELETE_SHOP_REQUEST,
    payload: shopId
  }
}