import React from 'react';

export const StatusOptions = props => {
  const { selectStatus, statuses, toggleVisibleStatusOptions } = props;
  return (
    <div className="Filter__form__select__options-block">
      {statuses.map((elem, index) => <p className="Filter__form__select_option" key={index + 1} onClick={ev => {
        selectStatus(ev.target.textContent);
        toggleVisibleStatusOptions(false);
      }}>{elem}</p>)}
    </div>
  )
}
