import React, {useEffect} from 'react';
import { getShops } from "../../actions/shops";

import Info from "./Info";
import Shops from "./Shops";

import "./Profile.css"
import { connect } from 'react-redux';

function Profile(props) {
  useEffect(()=>{
      props.getShops();
  }, []);
  return (
    <div className="Profile">
      <div className="container">
        <h1 className="Pagetitle">Ваш профиль</h1>
        <Info />
        <Shops />
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  statusOfAuthorization: store.app.statusOfAuthorization,
});

export default connect(mapStateToProps, {getShops})(Profile);