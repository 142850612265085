export const PARTNERS = {
  GET_PARTNERS_REQUEST: 'GET_PARTNERS_REQUEST',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  ADD_NEW_PARTNER_REQUEST: 'ADD_NEW_PARTNER_REQUEST',
  ADD_NEW_PARTNER_SUCCESS: 'ADD_NEW_PARTNER_SUCCESS',
  SAVE_MODIFIED_DATA_REQUEST: 'SAVE_MODIFIED_DATA_REQUEST',
  SAVE_MODIFIED_DATA_SUCCESS: 'SAVE_MODIFIED_DATA_SUCCESS',
  DELETE_PARTNER_REQUEST: 'DELETE_PARTNER_REQUEST',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
}

const initialState = [];

export default function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case PARTNERS.RESET_STATE_SUCCESS:
      return [];
    case PARTNERS.GET_PARTNERS_SUCCESS:
      return [...action.payload];
    case PARTNERS.SAVE_MODIFIED_DATA_SUCCESS: {
      const newArr = [...state];
      for (let index = 0; index < newArr.length; index++) {
        if (`${newArr[index].id}` === `${action.payload.id}`) {
          newArr[index] = {...action.payload};
          break;
        };
      }
      return [...newArr];
    }
    case PARTNERS.DELETE_PARTNER_SUCCESS: {
      const newArr = [];
      for (let index = 0; index < state.length; index++) {
        if(`${state[index].id}` === `${action.payload.id}`) {
          continue;
        };
        newArr.push(state[index]);
      }
      return [...newArr];
    }
    default:
      return state;
  }
}