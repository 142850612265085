export const PROFILE = {
  SET_USER_DATA: 'SET_USER_DATA',
  ADD_USER_DATA_SUCCESS: 'ADD_USER_DATA_SUCCESS',
  ADD_USER_DATA_REQUEST: 'ADD_USER_DATA_REQUEST',
  SET_NEW_PASSWORD_REQUEST: 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  FAST_TRANSFER_MONEY_REQUEST: 'FAST_TRANSFER_MONEY_REQUEST',
  FAST_TRANSFER_MONEY_SUCCESS: 'FAST_TRANSFER_MONEY_SUCCESS',
  TRANSFER_MONEY_TO_PARTNER_REQUEST: 'TRANSFER_MONEY_TO_PARTNER_REQUEST',
  TRANSFER_MONEY_TO_PARTNER_SUCCESS: 'TRANSFER_MONEY_TO_PARTNER_SUCCESS',
}

const initialState = {
  id: '',
  name: '',
  surname: '',
  email: '',
  telephone: '',
  balance: 0,
  limit: 0,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE.TRANSFER_MONEY_TO_PARTNER_SUCCESS:
      return {
        ...state,
        balance: action.payload.balance,
        limit: action.payload.limit,
      }
    case PROFILE.RESET_STATE_SUCCESS:
      return {
        id: '',
        name: '',
        surname: '',
        email: '',
        telephone: '',
        balance: 0,
        limit: 0,
      }
    case PROFILE.SET_USER_DATA:
      return {
        ...action.payload,
        id: action.payload.id,
        name: action.payload.name,
        surname: action.payload.surname,
        telephone: action.payload.telephone,
        email: action.payload.email,
        balance: action.payload.balance,
        limit: action.payload.limit,
      }
    case PROFILE.ADD_USER_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case PROFILE.FAST_TRANSFER_MONEY_SUCCESS:
      return {
        ...state,
        balance: action.payload.balance,
        limit: action.payload.limit,
      }
    default:
      return state;
  }
}