import { put, takeEvery } from "redux-saga/effects";
import { OPERATIONS } from "../../reducers/operationsReducer";
import { APP } from "../../reducers/appReducer";
import { ApiGetOperations } from "../../services/ApiCalls";

function* getOperations() {
  try {
    const {data} = yield ApiGetOperations();
    yield put({
      type: OPERATIONS.GET_OPERATIONS_SUCCESS,
      payload: data,
    });
    yield put({
      type: APP.SET_OPERATIONS_LOADING_FALSE
    });
  } catch ({ response }) {
    console.error(response.data.errors);
  }
}

export default function* operationsSaga() {
  yield takeEvery(OPERATIONS.GET_OPERATIONS_REQUEST, getOperations);
}