import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setOrdersFilter, deleteFieldInOrdersFilter } from "../../actions/app";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

import { ShopNameOptions } from "./ShopsNamesOnFilter";
import { TypesOfPayments } from "./PaymentsNamesOnFilter";

const Filter = props => {
  const { orders, setOrdersFilter, deleteFieldInOrdersFilter } = props;
  let shopsNames = [];
  let typesOfPayments = [];
  const [visibleShopsOptions, toggleVisibleShopsOptions] = useState(false);
  const [visiblePaymentsOptions, toggleVisiblePaymentsOptions] = useState(false);
  const [selectedShop, selectShop] = useState('');
  const [selectedPayment, selectPayment] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [convertedDateFrom, setConvertedDateFrom] = useState('');
  const [convertedDateTo, setConvertedDateTo] = useState('');
  registerLocale('ru', ru);

  // при выборе какого-либо параметра в фильтре, добавляем эти параметры в стор
  useEffect(() => {
    const data = {};
    if (selectedShop || selectedPayment || convertedDateFrom || convertedDateTo) {
      if (selectedShop) {
        data.shopName = selectedShop;
      };
      if (selectedPayment) {
        data.typeName = selectedPayment;
      };
      if (convertedDateFrom) {
        data.dateFrom = convertedDateFrom;
      };
      if (convertedDateTo) {
        data.dateTo = convertedDateTo;
      };
      setOrdersFilter(data);
    };
  }, [selectedShop, selectedPayment, convertedDateFrom, convertedDateTo]);

  // заполняем массивы названий интернет магазинов и типов оплаты
  orders.forEach(elem => {
    if (!shopsNames.includes(elem.shopName) && elem.shopName !== null) {
      shopsNames.push(elem.shopName)
    };
    if (!typesOfPayments.includes(elem.typeName) && elem.typeName !== null) {
      typesOfPayments.push(elem.typeName)
    };
  });

  // переводим дату из формата new Date() в формат "01.01.2020"
  function convertDate(date, dataType) {
    const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const dateString = `${day}.${month}.${date.getFullYear()}`;
    if (dataType === 'from') {
      setConvertedDateFrom(dateString);
    } else {
      setConvertedDateTo(dateString);
    };
  }

  return (
    <div className="Filter">
      <h3 className="Filter__title block-title">Фильтр</h3>
      <form className="Filter__form">
        <div className="Filter__form__wrapper">
          <p className="Filter__form__inputTitle">Интернет-магазин</p>
          <div className="Filter__form__select" onClick={
            event => {
              if (event.target.className === 'Filter__form__select' || event.target.className === 'Filter__form__select_option' || event.target.className === 'Filter__form__select_arrow') {
                toggleVisibleShopsOptions(!visibleShopsOptions);
              }
            }}>
            {selectedShop}
            {selectedShop && <svg className="Filter__form__select_cross" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              selectShop('');
              deleteFieldInOrdersFilter('shopName');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
            <svg className="Filter__form__select_arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" onClick={() => toggleVisibleShopsOptions(!visibleShopsOptions)}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>
          </div>
          {visibleShopsOptions && <ShopNameOptions shopsNames={shopsNames} toggleVisibleShopsOptions={toggleVisibleShopsOptions} selectShop={selectShop} />}
        </div>
        <div className="Filter__form__wrapper">
          <p className="Filter__form__inputTitle">Дата</p>
          <div className="Filter__form__inputDate_wrapper">
            <DatePicker
              selected={dateFrom}
              onChange={date => {
                convertDate(date, 'from');
                setDateFrom(date);
              }}
              locale='ru'
              className="Filter__form__inputDate"
              dateFormat="d MMM yyyy"
            />
            {convertedDateFrom && <svg className="Filter__form__inputDate_clear-btn" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              setConvertedDateFrom('');
              setDateFrom('');
              deleteFieldInOrdersFilter('dateFrom');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
          </div>
          <div className="Filter__form__inputDate_wrapper">
            <DatePicker
              selected={dateTo}
              onChange={date => {
                convertDate(date, 'to');
                setDateTo(date);
              }}
              locale='ru'
              className="Filter__form__inputDate"
              dateFormat="d MMM yyyy"
            />
            {convertedDateTo && <svg className="Filter__form__inputDate_clear-btn" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              setConvertedDateTo('');
              setDateTo('');
              deleteFieldInOrdersFilter('dateTo');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
          </div>
        </div>
        <div className="Filter__form__wrapper">
          <p className="Filter__form__inputTitle">Тип оплаты</p>
          <div className="Filter__form__select" onClick={
            event => {
              if (event.target.className === 'Filter__form__select' || event.target.className === 'Filter__form__select_option' || event.target.className === 'Filter__form__select_arrow') {
                toggleVisiblePaymentsOptions(!visiblePaymentsOptions)
              }
            }}>
            {selectedPayment}
            {selectedPayment && <svg className="Filter__form__select_cross" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
              selectPayment('');
              deleteFieldInOrdersFilter('typeName');
            }}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
              <path d="M1 9L5 5L9 9" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>}
            <svg className="Filter__form__select_arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" onClick={() => toggleVisiblePaymentsOptions(!visiblePaymentsOptions)}>
              <path d="M1 1L5 5L9 1" stroke="#0B3043" strokeOpacity="0.4" />
            </svg>
          </div>
          {visiblePaymentsOptions && <TypesOfPayments selectPayment={selectPayment} typesOfPayments={typesOfPayments} toggleVisiblePaymentsOptions={toggleVisiblePaymentsOptions} />}
        </div>
      </form>
      <div className="Filter__download">
        скачать file.xlsx
          <svg className="Filter__download__icon" width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 20.625V1.375C0 1.10764 0.0643229 0.878472 0.192969 0.6875C0.321615 0.486979 0.489844 0.319878 0.697656 0.186198C0.935156 0.062066 1.1776 0 1.425 0H10.925C11.2021 0 11.4891 0.0477431 11.7859 0.143229C12.0927 0.229167 12.3846 0.34375 12.6617 0.486979C12.919 0.62066 13.1516 0.782986 13.3594 0.973958C13.7057 1.30816 14.1906 1.77843 14.8141 2.38477C15.4375 2.9911 16.0065 3.54253 16.5211 4.03906C17.0357 4.53559 17.5255 5.00347 17.9906 5.44271C18.1885 5.64323 18.3568 5.86762 18.4953 6.11589C18.6437 6.38325 18.7625 6.66493 18.8516 6.96094C18.9505 7.2474 19 7.52431 19 7.79167V20.625C19 20.8637 18.9357 21.0977 18.807 21.3268C18.6685 21.5273 18.4953 21.6897 18.2875 21.8138C18.0896 21.9379 17.8521 22 17.575 22H1.425C1.15781 22 0.915365 21.9379 0.697656 21.8138C0.489844 21.6897 0.321615 21.5273 0.192969 21.3268C0.0643229 21.1168 0 20.8828 0 20.625ZM17.1 20.1667V7.99219C17.1 7.65799 17.006 7.32378 16.818 6.98958C16.7388 6.8559 16.6844 6.77474 16.6547 6.74609L12.0086 2.26302C11.9195 2.16753 11.7365 2.07205 11.4594 1.97656C11.1229 1.88108 10.8607 1.83333 10.6727 1.83333H1.9V20.1667H17.1ZM11.4 5.95833V11.9167H14.725C14.8042 11.9167 14.8932 11.9405 14.9922 11.9883C15.0714 12.036 15.1258 12.1029 15.1555 12.1888C15.1852 12.2461 15.2 12.332 15.2 12.4466C15.1901 12.5326 15.1505 12.6137 15.0812 12.6901L9.84141 18.1901C9.80182 18.2283 9.75234 18.2617 9.69297 18.2904C9.63359 18.319 9.56927 18.3333 9.5 18.3333C9.41094 18.3238 9.34661 18.3095 9.30703 18.2904C9.22786 18.2522 9.17344 18.2188 9.14375 18.1901L3.93359 12.6901C3.86432 12.6137 3.82474 12.5326 3.81484 12.4466C3.80495 12.3702 3.81484 12.2843 3.84453 12.1888C3.88411 12.122 3.94349 12.0551 4.02266 11.9883C4.10182 11.9405 4.18594 11.9167 4.275 11.9167H7.6V5.95833C7.6 5.82465 7.64453 5.71484 7.73359 5.62891C7.82266 5.54297 7.93646 5.5 8.075 5.5H10.925C11.0635 5.5 11.1773 5.54297 11.2664 5.62891C11.3555 5.71484 11.4 5.82465 11.4 5.95833Z" fill="#0B3043" />
        </svg>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  orders: store.orders,
});

export default connect(mapStateToProps, { setOrdersFilter, deleteFieldInOrdersFilter })(Filter)