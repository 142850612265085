export const OPERATIONS = {
  GET_OPERATIONS_REQUEST: 'GET_OPERATIONS_REQUEST',
  GET_OPERATIONS_SUCCESS: 'GET_OPERATIONS_SUCCESS',
};

const initialState = [];

export default function operationsReducer(state = initialState, action) {
  switch (action.type) {
    case OPERATIONS.RESET_STATE_SUCCESS:
      return [];
    case OPERATIONS.GET_OPERATIONS_SUCCESS:
      return [
        ...action.payload
      ];
    default:
      return state;
  }
}