import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { requestCall } from "../../actions/app";
import { connect } from "react-redux";

export function RequestCallForm(props) {
  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Введите имя')
      .min(3, 'Минимальная длина 3 символа'),
    phone: Yup.number()
      .required('Введите телефон')
      .positive()
      .typeError('Поле должно содержать только цифры')
      .min(99999, 'телефон не может быть короче 6 символов')
      .max(100000000000, 'телефон не может быть длиннее 11 символов'),

  });
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      comment: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      props.requestCall(values)
    }
  });
  const { values, errors, handleSubmit, handleChange, touched } = formik;
  return (
    <form className="Login__form" onSubmit={handleSubmit}>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.name && errors.name) && 'Login__input-invalid'}`}
          type="text"
          name='name'
          placeholder="Имя*"
          value={values.name}
          onChange={handleChange}
        />
        {errors.name && touched.name && <div className='Login__invalid-message'>{errors.name}</div>}
      </div>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.phone && errors.phone) && 'Login__input-invalid'}`}
          type="text"
          name='phone'
          placeholder="Телефон*"
          value={values.phone}
          onChange={handleChange}
        />
        {errors.phone && touched.phone && <div className='Login__invalid-message'>{errors.phone}</div>}
      </div>
      <div className="Login__form__wrapper">
        <textarea
          className='Login__input modal__input'
          name='comment'
          placeholder="Комментарий"
          value={values.comment}
          onChange={handleChange}
          rows='5'
        />
      </div>
      <div className="Login__row">
        <p>Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности</p>
      </div>
      <button className="Login__submitBtn" type="submit">Отправить</button>
    </form>
  )
}

export default connect(null, {requestCall})(RequestCallForm)