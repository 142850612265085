import { ORDERS } from "../../reducers/ordersReducer";

export const getOrders = () => ({
  type: ORDERS.GET_ORDERS_REQUEST
})

export function addNewOrder(orderData) {
  return {
    type: ORDERS.ADD_NEW_ORDER_REQUEST,
    payload: orderData,
  }
}

export function deleteOrder(orderId) {
  return {
    type: ORDERS.DELETE_ORDER_REQUEST,
    payload: {id: orderId }
  }
}