import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { addNewShop } from "../../actions/shops";
import { hideModal } from "../../utility/functions";
import { AddShopModalRef } from "../../components/Modals/AddShopModal";

const AddNewShopForm = props => {
  const shema = Yup.object().shape({
    shopName: Yup.string()
      .max(100, 'Значение не может быть больше 100 символов')
      .required('Поле должно быть заполнено'),
    shopLink: Yup.string()
      .max(100, 'Значение не может быть больше 100 символов')
      .required('Поле должно быть заполнено'),
    typeOfOrganization: Yup.string()
      .required('Поле должно быть заполнено'),
    organizationName: Yup.string()
      .max(100, 'Значение не может быть больше 100 символов')
      .required('Поле должно быть заполнено'),
    organizationINN: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .required('Поле должно быть заполнено'),
    organizationKPP: Yup.number()
      .typeError('Поле должно содержать только цифры'),
    organizationOGRN: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .required('Поле должно быть заполнено'),
    organizationURAdress: Yup.string()
      .max(150, 'Значение не может быть больше 150 символов')
      .required('Поле должно быть заполнено'),
    organizationPostAdress: Yup.string()
      .max(150, 'Значение не может быть больше 150 символов')
      .required('Поле должно быть заполнено'),
    bankName: Yup.string()
      .max(150, 'Значение не может быть больше 150 символов')
      .required('Поле должно быть заполнено'),
    bankBIK: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .required('Поле должно быть заполнено'),
    bankKorAccount: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .required('Поле должно быть заполнено'),
    bankRashAccount: Yup.number()
      .typeError('Поле должно содержать только цифры')
      .required('Поле должно быть заполнено'),
  });

  const formik = useFormik({
    initialValues: {
      shopName: '',
      shopLink: '',
      typeOfOrganization: '',
      organizationName: '',
      organizationINN: '',
      organizationKPP: '',
      organizationOGRN: '',
      organizationURAdress: '',
      organizationPostAdress: '',
      bankName: '',
      bankBIK: '',
      bankKorAccount: '',
      bankRashAccount: '',
      organization_type: 1
    },
    validationSchema: shema,
    onSubmit: values => {
      props.addNewShop(values);
      hideModal(null, AddShopModalRef.current, true);
      setTimeout(() => {
        for (let key in values) {
          values[key] = '';
        }
      }, 1500);
    },
  })

  const { touched, errors, handleSubmit, handleChange, values } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal__form">
        <p className="modal__form_title">Общие сведения</p>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.shopName && touched.shopName && 'invalid'}`}
            type="text"
            name='shopName'
            placeholder="Наименование интернет-магазина"
            onChange={handleChange}
            value={values.shopName}
            autoComplete="off"
          />
          {errors.shopName && touched.shopName && <div className='invalid-message'>{errors.shopName}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.shopLink && touched.shopLink && 'invalid'}`}
            type="text"
            name='shopLink'
            placeholder="Ссылка"
            onChange={handleChange}
            value={values.shopLink}
            autoComplete="off"
          />
          {errors.shopLink && touched.shopLink && <div className='invalid-message'>{errors.shopLink}</div>}
        </div>
      </div>
      <div className="modal__form">
        <p className="modal__form_title">Реквизиты организации</p>
        <div className="modal__form_wrapper modal__select__wrapper">
          <select
            className={`modal__input modal__select ${errors.typeOfOrganization && touched.typeOfOrganization && 'invalid'}`}
            name='typeOfOrganization'
            onChange={handleChange}
            value={values.typeOfOrganization}
          >
            <option value="" disabled>Тип организации</option>
            <option value="1">ИП</option>
            <option value="2">ООО</option>
            <option value="3">ОАО</option>
            <option value="4">ЗАО</option>
          </select>
          {errors.typeOfOrganization && touched.typeOfOrganization && <div className='invalid-message'>{errors.typeOfOrganization}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.organizationName && touched.organizationName && 'invalid'}`}
            type="text"
            name='organizationName'
            placeholder="Название организации"
            onChange={handleChange}
            value={values.organizationName}
            autoComplete="off"
          />
          {errors.organizationName && touched.organizationName && <div className='invalid-message'>{errors.organizationName}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.organizationINN && touched.organizationINN && 'invalid'}`}
            type="text"
            name='organizationINN'
            placeholder="ИНН"
            onChange={handleChange}
            value={values.organizationINN}
            autoComplete="off"
          />
          {errors.organizationINN && touched.organizationINN && <div className='invalid-message'>{errors.organizationINN}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.organizationKPP && touched.organizationKPP && 'invalid'}`}
            type="text"
            name='organizationKPP'
            placeholder="КПП"
            onChange={handleChange}
            value={values.organizationKPP}
            autoComplete="off"
          />
          {errors.organizationKPP && touched.organizationKPP && <div className='invalid-message'>{errors.organizationKPP}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.organizationOGRN && touched.organizationOGRN && 'invalid'}`}
            type="text"
            name='organizationOGRN'
            placeholder="ОГРН/ОГРНИП"
            onChange={handleChange}
            value={values.organizationOGRN}
            autoComplete="off"
          />
          {errors.organizationOGRN && touched.organizationOGRN && <div className='invalid-message'>{errors.organizationOGRN}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.organizationURAdress && touched.organizationURAdress && 'invalid'}`}
            type="text"
            name='organizationURAdress'
            placeholder="Юридический адрес"
            onChange={handleChange}
            value={values.organizationURAdress}
            autoComplete="off"
          />
          {errors.organizationURAdress && touched.organizationURAdress && <div className='invalid-message'>{errors.organizationURAdress}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.organizationPostAdress && touched.organizationPostAdress && 'invalid'}`}
            type="text"
            name='organizationPostAdress'
            placeholder="Почтовый адрес"
            onChange={handleChange}
            value={values.organizationPostAdress}
            autoComplete="off"
          />
          {errors.organizationPostAdress && touched.organizationPostAdress && <div className='invalid-message'>{errors.organizationPostAdress}</div>}
        </div>
      </div>
      <div className="modal__form">
        <p className="modal__form_title">Банковские реквизиты</p>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.bankName && touched.bankName && 'invalid'}`}
            type="text"
            name='bankName'
            placeholder="Название банка"
            onChange={handleChange}
            value={values.bankName}
            autoComplete="off"
          />
          {errors.bankName && touched.bankName && <div className='invalid-message'>{errors.bankName}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.bankBIK && touched.bankBIK && 'invalid'}`}
            type="text"
            name='bankBIK'
            placeholder="БИК"
            onChange={handleChange}
            value={values.bankBIK}
            autoComplete="off"
          />
          {errors.bankBIK && touched.bankBIK && <div className='invalid-message'>{errors.bankBIK}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.bankKorAccount && touched.bankKorAccount && 'invalid'}`}
            type="text"
            name='bankKorAccount'
            placeholder="Корреспондентский счёт"
            onChange={handleChange}
            value={values.bankKorAccount}
            autoComplete="off"
          />
          {errors.bankKorAccount && touched.bankKorAccount && <div className='invalid-message'>{errors.bankKorAccount}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.bankRashAccount && touched.bankRashAccount && 'invalid'}`}
            type="text"
            name='bankRashAccount'
            placeholder="Расчётный счёт"
            onChange={handleChange}
            value={values.bankRashAccount}
            autoComplete="off"
          />
          {errors.bankRashAccount && touched.bankRashAccount && <div className='invalid-message'>{errors.bankRashAccount}</div>}
        </div>
      </div>
      <button className="modal__submitBtn" type="submit">Сохранить</button>
    </form >
  )
};

export default connect(() => ({}), { addNewShop })(AddNewShopForm)