import React from 'react';
import { Link } from "react-router-dom";

import LoginPageForm from "../Forms/LoginPageForm";

import "./Login.css";

export const LoginPage = () => {
  return (
    <>
      <div className="Login_wrapper">
        <div className=" Login">
          <h3 className="Login__title">Вход в личный кабинет</h3>
          <LoginPageForm />
          <Link to="/registration" className="Login__registration-link">Зарегистрироваться</Link>
        </div>
      </div>
    </>
  )
}