import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { recoveryPass } from "../../actions/app";

const RecoveryPassForm = props => {
  const { recoveryPass } = props;
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('Введите почту')
      .email('Введите почту')
      .min(3, 'Минимальная длина 3 символа')});
  const formik = useFormik({
    initialValues: {email: ''},
    validationSchema: schema,
    onSubmit: values => {
      recoveryPass(values);
    }
  });
  const { values, errors, handleSubmit, handleChange, touched } = formik;
  return (
    <form className="Login__form" onSubmit={handleSubmit}>
      <div className="Login__form__wrapper">
        <input
          className={`Login__input modal__input ${(touched.email && errors.email) && 'Login__input-invalid'}`}
          type="text"
          name='email'
          placeholder="Email"
          value={values.email}
          onChange={handleChange}
        />
        {errors.email && touched.email && <div className='Login__invalid-message'>{errors.email}</div>}
      </div>
      <button className="Login__submitBtn" type="submit">Отправить</button>
    </form>
  )
}

export default connect(() => ({}), { recoveryPass })(RecoveryPassForm)