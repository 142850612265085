import React from 'react';
import { connect } from "react-redux";
import { showModal } from "../../utility/functions";

import { AddPartnerModal, AddPartnerModalRef } from "../Modals/AddPartnerModal";
import { PartnersListItem } from "./PartnersListItem";
import { EditPartnerModal } from "../Modals/EditPartnerModal";
import { Preloader } from "../Modals/Preloader";
import {TransferMoneyModal} from "../Modals/TransferMoneyModal";

const Partners = props => {
  const { partners, editablePartner, partnersLoading, accountToTransfer } = props;

  return (
    <div className="Shops Partners">
      <AddPartnerModal />
      {editablePartner && <EditPartnerModal />}
      {accountToTransfer && <TransferMoneyModal />}
      <h3 className="Shops__title Info__info__title">Контрагенты</h3>
      <div className="Shops__shops-block">
        {partnersLoading && <Preloader />}
        {partners.map((elem, index) => <PartnersListItem elem={elem} indexInArr={index} key={elem.id} />)}
      </div>
      <button className="Shops__addShop"
        onClick={() => showModal(AddPartnerModalRef.current)}
      >
        добавить контрагента
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#0B3043" />
        </svg>
      </button>
    </div>
  )
}

const mapStateToProps = store => ({
  partners: store.partners,
  editablePartner: store.app.editablePartner,
  partnersLoading: store.app.partnersLoading,
  accountToTransfer: store.app.accountToTransfer,
});

export default connect(mapStateToProps)(Partners)