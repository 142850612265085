import React from 'react';
import { connect } from 'react-redux';
import { toggleVisibleDropDownMenu } from "../../actions/app";

import logoMob from '../../img/logo_mob.jpg'


const TopMenuMob = props => {
  const {visibleDropDownMenu, toggleVisibleDropDownMenu} = props;

  return (
    <div className="TopMenuMob">
      <div className="container">
        <a href="/">
          <img src={logoMob} alt="logo" className="TopMenuMob__logo" />
        </a>
        <div className={`${visibleDropDownMenu ? 'TopMenuMob__close-btn' : 'TopMenuMob__burger-menu'}`} onClick={toggleVisibleDropDownMenu}>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  visibleDropDownMenu: store.app.visibleDropDownMenu,
});

export default connect(mapStateToProps, {toggleVisibleDropDownMenu})(TopMenuMob)