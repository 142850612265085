import React from 'react';

export const ShopNameOptions = props => {
  const { shopsNames, selectShop, toggleVisibleShopsOptions } = props;
  return (
    <div className="Filter__form__select__options-block">
      {shopsNames.map((elem, index) => <p className="Filter__form__select_option" key={index + 1} onClick={ev => {
        selectShop(ev.target.textContent);
        toggleVisibleShopsOptions(false);
        }}>{elem}</p>)}
    </div >
  )
}
