import React, { useState } from 'react';
import { connect } from "react-redux";
import { setLimit } from "../../actions/users";
import { blockUser, unlockUser } from "../../actions/users";

export const TableRow = props => {
  const [percent, setPercent] = useState(0);
  const { setLimit, blockUser, unlockUser } = props;
  const { name, surname, id, limit_percents, limit_integer, telephone, email, status, balance, total_orders } = props.elem
  return (
    <tr>
      <td>
        <p className='AdminPanel__userData'>{name && name + ' '}{surname && surname}</p>
        <p className='AdminPanel__userData'>email: {email}</p>
        <p className='AdminPanel__userData'>телефон: {telephone && telephone}</p>
      </td>
      <td>
        <p className='AdminPanel__userData'>
          <input
            className="AdminPanel__input"
            type="number"
            placeholder={limit_percents}
            onChange={event => setPercent(event.target.value)}
          />
          <button
            onClick={() => {
              setLimit({
                limit: +(percent),
                id: id,
              });
            }}
            title='установить лимит в процентах'>Сохранить</button>
        </p>
        <p className='AdminPanel__userData'>Сумма заказов: {total_orders + ' руб.'}</p>
        <p className='AdminPanel__userData'>Лимит: {limit_integer + ' руб.'}</p>
        <p className='AdminPanel__userData'>Баланс: {balance + ' руб.'}</p>
      </td>
      <td>
        {status ?
          <button onClick={() => blockUser(id)}>Заблокировать</button> :
          <button onClick={() => unlockUser(id)}>Разблокировать</button>}
      </td>
    </tr >
  )
}

export default connect(null, { setLimit, blockUser, unlockUser })(TableRow)