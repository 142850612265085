import { takeEvery, put, call } from "redux-saga/effects";
import { APP } from "../../reducers/appReducer";
import { PROFILE } from "../../reducers/profileReducer";
import { ApiGetProfile, ApiAuthorize, ApiRegistration, ApiRecoveryPass, ApiResetPass, ApiRequestCall } from "../../services/ApiCalls";
import { store } from "../../index";
import { showModal, setDelay, showMessage, hideModal } from "../../utility/functions";
import { EditShopModalRef } from "../../components/Modals/EditShopModal";
import { EditPartnerModalRef } from "../../components/Modals/EditPartnerModal";
import { transferMoneyModalRef } from "../../components/Modals/TransferMoneyModal";
import { history } from "../../index";
import { RequestCalRef } from "../../components/Modals/RequestCallModal";

function* getProfile() {
  try {
    const profileData = yield ApiGetProfile();
    yield put({
      type: APP.GET_PROFILE_SUCCESS,
    });
    yield put({
      type: PROFILE.SET_USER_DATA,
      payload: profileData.data
    });
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* getAuthorization(action) {
  try {
    const data = yield ApiAuthorize(action.payload);
    yield action.remember ? localStorage.setItem('token', data.token) : sessionStorage.setItem('token', data.token);
    if (data.user.status === 0) {
      yield showMessage('error', 'Данный пользователь заблокирован');
      return null;
    };
    if (data.user.role_id === 1) {
      yield history.replace('/adminPanel');
      yield localStorage.setItem('isAdmin', true);
      return null;
    };
    yield put({
      type: PROFILE.SET_USER_DATA,
      payload: data.user
    });
    yield put({
      type: APP.GET_AUTHORIZATION_SUCCESS,
    });
    yield history.replace('/');
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* setEditableShop(action) {
  const shops = store.getState().shops;
  const shopData = shops.find(item => `${item.id}` === `${action.payload}`)
  yield put({
    type: APP.SET_EDITABLE_SHOP_SUCCESS,
    payload: shopData
  });
  yield call(setDelay, 50);
  yield showModal(EditShopModalRef.current);
}

function* removeEditableShop() {
  yield put({
    type: APP.REMOVE_EDITABLE_SHOP_SUCCESS
  })
}

function* setEditablePartner(action) {
  const partners = store.getState().partners;
  const partnerData = partners.find(item => `${item.id}` === `${action.payload}`)
  yield put({
    type: APP.SET_EDITABLE_PARTNER_SUCCESS,
    payload: partnerData
  });
  yield call(setDelay, 50);
  yield showModal(EditPartnerModalRef.current);
}

function* removeEditablePartner() {
  yield put({
    type: APP.REMOVE_EDITABLE_PARTNER_SUCCESS
  })
}

function* setOrdersFilter(action) {
  yield put({
    type: APP.SET_ORDERS_FILTER_SUCCESS,
    payload: action.payload,
  });
}

function* deleteFieldInOrdersFilter(action) {
  yield put({
    type: APP.DELETE_ORDERS_FILTER_FIELD_SUCCESS,
    payload: action.payload
  });
}

function* toggleVisibleDropDownMenu() {
  yield put({
    type: APP.TOGGLE_VISIBLE_DROPDOWN_MENU_SUCCESS
  });
}

function* resetAuthorization() {
  if (sessionStorage.token) {
    sessionStorage.removeItem('token');
  } else if (localStorage.token) {
    localStorage.removeItem('token');
  };
  yield put({
    type: APP.RESET_AUTHORIZATION_SUCCESS
  });
  yield history.replace('/login');
}

function* setAccountToTransfer(action) {
  const source = action.payload.type === 'shop' ?
    store.getState().shops :
    store.getState().partners;
  const elem = source.find(elem => `${elem.id}` === `${action.payload.id}`);
  yield put({
    type: APP.SET_ACCOUNT_TO_TRANSFER_SUCCESS,
    payload: elem.bankRashAccount
  });
  yield call(setDelay, 50);
  transferMoneyModalRef.current.setAttribute('agentid', action.payload.id)
  yield showModal(transferMoneyModalRef.current);
}

function* removeAccountToTransfer() {
  yield put({
    type: APP.REMOVE_ACCOUNT_TO_TRANSFER_SUCCESS
  })
}

function* getRegistration(action) {
  try {
    yield ApiRegistration(action.payload);
    yield showMessage('success', 'Регистрация прошла успешно');
    yield setDelay(2500);
    yield history.push('/login')
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* setOperationsFilter(action) {
  yield put({
    type: APP.SET_OPERATIONS_FILTER_SUCCESS,
    payload: action.payload,
  });
}

function* deleteFieldInOperationsFilter(action) {
  yield put({
    type: APP.DELETE_OPERATIONS_FILTER_FIELD_SUCCESS,
    payload: action.payload
  });
}

function* recoveryPass(action) {
  try {
    const { data } = yield ApiRecoveryPass(action.payload);
    if (data.error) {
      yield showMessage('error', data.error, 3000);
    } else {
      yield showMessage('success', 'Письмо с ссылкой для восстановления пароля отправлено на Вашу почту', 5000);
    }
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* resetPass(action) {
  try {
    yield ApiResetPass(action.payload);
    yield showMessage('success', 'Пароль изменен');
    yield setDelay(2500);
    history.replace('/login');
  } catch ({ response }) {
    console.error(response.data.errors);
    showMessage('error', response.data.errors);
  }
}

function* requestCall(action) {
  try {
    yield ApiRequestCall(action.payload);
    hideModal(null, RequestCalRef.current, true);
    showMessage('success', 'Ваша заявка отправлена');
  } catch ({ response }) {
    console.error(response.data.errors);
    hideModal(null, RequestCalRef.current, true);
    showMessage('error', 'Ошибка отправки данных');
  }
}

export default function* appSaga() {
  yield takeEvery(APP.GET_PROFILE_REQUEST, getProfile);
  yield takeEvery(APP.GET_AUTHORIZATION_REQUEST, getAuthorization);
  yield takeEvery(APP.SET_EDITABLE_SHOP_REQUEST, setEditableShop);
  yield takeEvery(APP.REMOVE_EDITABLE_SHOP_REQUEST, removeEditableShop);
  yield takeEvery(APP.SET_EDITABLE_PARTNER_REQUEST, setEditablePartner);
  yield takeEvery(APP.REMOVE_EDITABLE_PARTNER_REQUEST, removeEditablePartner);
  yield takeEvery(APP.SET_ORDERS_FILTER_REQUEST, setOrdersFilter);
  yield takeEvery(APP.DELETE_ORDERS_FILTER_FIELD_REQUEST, deleteFieldInOrdersFilter);
  yield takeEvery(APP.TOGGLE_VISIBLE_DROPDOWN_MENU_REQUEST, toggleVisibleDropDownMenu);
  yield takeEvery(APP.RESET_AUTHORIZATION_REQUEST, resetAuthorization);
  yield takeEvery(APP.SET_ACCOUNT_TO_TRANSFER_REQUEST, setAccountToTransfer);
  yield takeEvery(APP.REMOVE_ACCOUNT_TO_TRANSFER_REQUEST, removeAccountToTransfer);
  yield takeEvery(APP.GET_REGISTRATION_REQUEST, getRegistration);
  yield takeEvery(APP.SET_OPERATIONS_FILTER_REQUEST, setOperationsFilter);
  yield takeEvery(APP.DELETE_OPERATIONS_FILTER_FIELD_REQUEST, deleteFieldInOperationsFilter);
  yield takeEvery(APP.RECOVERY_PASS_REQUEST, recoveryPass);
  yield takeEvery(APP.RESER_PASS_REQUEST, resetPass);
  yield takeEvery(APP.REQUEST_CALL_REQUEST, requestCall);
}