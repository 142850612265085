import React from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { transferToPartner } from "../../actions/profile";
import { hideModal } from "../../utility/functions";
import { transferMoneyModalRef } from "../../components/Modals/TransferMoneyModal";

const TransferMoneyForm = props => {
  const { limit, accountToTransfer, transferToPartner } = props;

  const shema = Yup.object().shape({
    total: Yup.number()
      .required('Введите сумму перевода')
      .positive('Сумма должна быть положительным')
      .min(1, 'Минимальная сумма перевода 1 рубль')
      .max(limit, 'Сумма не должна превышать лимит')
      .typeError('Допустимы только цифры')
      .integer('Допустимы только целые числа')
  });

  const formik = useFormik({
    initialValues: {
      accountToTransfer: accountToTransfer,
      total: '',
    },
    validationSchema: shema,
    onSubmit: values => {
      transferToPartner({...values, agentId: transferMoneyModalRef.current.getAttribute('agentid')});
      hideModal(null, transferMoneyModalRef.current, true);
    },
  });

  const { touched, errors, handleSubmit, handleChange, values } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal__form">
        <div className="modal__form_wrapper">
          <input
            className={`modal__input ${errors.total && touched.total && 'invalid'}`}
            type="number"
            name='total'
            placeholder="Сумма перевода"
            onChange={handleChange}
            value={values.total}
            autoComplete="off"
          />
          {errors.total && touched.total && <div className='invalid-message'>{errors.total}</div>}
        </div>
        <div className="modal__form_wrapper">
          <input
            className='modal__input'
            type="text"
            name='accountToTransfer'
            value={values.accountToTransfer}
            disabled
          />
        </div>
      </div>
      <button className="modal__submitBtn" type="submit">Сохранить</button>
    </form>
  )
}

const mapStateToProps = store => ({
  accountToTransfer: store.app.accountToTransfer,
  limit: store.profile.limit,
});

export default connect(mapStateToProps, { transferToPartner })(TransferMoneyForm)