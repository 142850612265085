import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import { getProfile } from '../actions/app';

import Header from "./Header/Header";
import { Footer } from "./Footer/Footer";
import Orders from "./Orders/Orders";
import Profile from "./Profile/Profile";
import Operations from "./Operations/Operations";
import FastTransfers from "./GetMoney/FastTransfers";
import Financing from "./GetMoney/Financing";
import { PrivateRoute } from "./Route/PrivateRoute";
import { RequestCall } from "../components/Modals/RequestCallModal";
import ConfirmExit from "./Modals/ConfirmExit";

import './Modals/Modals.css';


const Layout = props => {
  const { getProfile } = props;

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Header />
      <RequestCall />
      <ConfirmExit />
      <Switch>
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/operations" component={Operations} />
        <PrivateRoute path="/fastTransfers" component={FastTransfers} />
        <PrivateRoute path="/financing" component={Financing} />
        <PrivateRoute exact path="/" component={Profile} />
      </Switch>
      <Footer />
    </>
  );
}

export default withRouter(connect(null, { getProfile })(Layout));